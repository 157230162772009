import React from 'react';
import {Modal, Button} from 'react-bootstrap'

class App extends React.Component {
    constructor(){
        super();
        this.state = {
            show: false,
        }
    }

handleModal(){
    this.setState({show:!this.state.show})
}

render() {
    return(
        <>
        <br /><br /><br /><br /><br /><br />
        <button type="button" className="btn btn-primary" onClick={()=>this.handleModal()} >&pound; 495.45</button>
        
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={this.state.show} onHide={()=>this.handleModal()}>
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Centered Modal</h4>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=>this.handleModal()}>Close</Button>
      </Modal.Footer>
    </Modal>
        </>
        );
}

}

export default App;