import React from 'react';
import { Table } from 'react-bootstrap';
import HomeHeroBanner from "../assets/banner/auckland.jpg";
import FlightOffer from "../assets/images/FlightOffer.png";
import flightiCON from "../assets/Airline/flight.png";
import EK from "../assets/Airline/EK.png";
import BA from "../assets/Airline/BA.png";
import QR from "../assets/Airline/QR.png";
import TG from "../assets/Airline/TG.png";
import MH from "../assets/Airline/MH.png";
import CX from "../assets/Airline/CX.png";
import SearchEng from '../Company/SearchEng';
export default function Auckland() {
    return (
        <>
            <div className="HeroBannerHome" style={{ backgroundImage: `url(${HomeHeroBanner})` }}>
                <div className="container dest">
                    <div className="row">
                        <div className="col-md-12 headertext">
                            <h1>Now Book Your Cheap Flight Tickets to <strong>Auckland.</strong></h1>
                            <p>Discounted Worldwide Tickets with Fly2Go.</p>
                        </div>
                        <div className="col-md-12">
                            <div className="mouse_scroll">

                                <div className="mouse">
                                    <div className="wheel"></div>
                                </div>
                                <div>
                                    <span className="m_scroll_arrows unu"></span>
                                    <span className="m_scroll_arrows doi"></span>
                                    <span className="m_scroll_arrows trei"></span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <SearchEng />
            <section className="flightPack flightoffer">
                <div className="container dest">
                    <div className="row">
                        <div className="col-md-9">
                            <Table bordered hover>
                                <tbody>
                                    <tr>
                                        <td><img src={EK} alt="EK" className="tairLogo" /></td>
                                        <td>LONDON(All)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>AUCKLAND(AKL)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 595.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={TG} alt="TG" className="tairLogo" /></td>
                                        <td>BIRMINGHAM(BHX)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>AUCKLAND(AKL)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 615.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={QR} alt="QR" className="tairLogo" /></td>
                                        <td>DUBLIN(DUB)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>AUCKLAND(AKL)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 645.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={BA} alt="BA" className="tairLogo" /></td>
                                        <td>EDIBURGH(EDI)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>AUCKLAND(AKL)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 695.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={MH} alt="MH" className="tairLogo" /></td>
                                        <td>GLASGOW(GLA)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>AUCKLAND(AKL)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 715.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={CX} alt="CX" className="tairLogo" /></td>
                                        <td>MANCHESTER(MAN)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>AUCKLAND(AKL)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 745.45</button></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-md-3"><img src={FlightOffer} alt="FlightOffer" className="FlightOffer" /> </div>
                    </div>
                    <div className="row page alignLeft">
                        <div className="col-md-12">
                            <h3>Book Cheap Flight Tickets with Fly2Go</h3>
                             </div>

                    </div>
                </div>
            </section>
        </>
    )
}
