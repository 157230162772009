import React from 'react';
import { MetaTags } from 'react-meta-tags';
import { Table } from 'react-bootstrap';
import HomeHeroBanner from "../assets/banner/JakartaBanner.jpg";
import FlightOffer from "../assets/images/FlightOffer.png";
import flightiCON from "../assets/Airline/flight.png";
import MH from "../assets/Airline/MH.png";
import CX from "../assets/Airline/CX.png";
import QR from "../assets/Airline/QR.png";
import TK from "../assets/Airline/TK.png";
import TG from "../assets/Airline/TG.png";
import EK from "../assets/Airline/EK.png";
import SearchEng from '../Company/SearchEng';
export default function Jakarta() {
    return (
        <>
        <div className="HeroBannerHome" style={{ backgroundImage: `url(${HomeHeroBanner})` }}>
        <MetaTags>
  <title>Fly2go offers you best deal of the season to Jakarta, Indonesia from various cities of UK. Hurry UP! Call us on 0208-095-0550.</title>
  <meta name="description" content="KLM airlines offers you discounted flight tickets fare to Jakarta From UK for Business class as well as for economic Class tickets. Indonesia is a famous tourist destination due to its warm climate and natural beauty. Discover the best Jakarta Flight prices and book your trip with Fly2go now. Call us at 0208-095-0550." />
	<meta name="Keywords" content="Business class Flight Fare, cheap flight tickets to Indonesia/Jakarta, cheap price deal, best flight deal to Jakarta, discounted flight fare in KLM" />
  <meta name="robots" content="all" />
  <meta name=" author" content=" Fly2go " />
  <meta name="Language" content="English" />
  <meta name="distribution" content="Global" />
  <meta name="Robots" content="INDEX, FOLLOW" />
  <meta name="country" content="United Kingdom" />
  <link rel="canonical" href="https://www.fly2go.co.uk/Jakarta" />
</MetaTags>
            <div className="container dest">
                <div className="row">
                    <div className="col-md-12 headertext">
                        <h1>Now Book Your Cheap Flight Tickets to <strong>Jakarta.</strong></h1>
                        <p>Discounted Worldwide Tickets with Fly2Go.</p>
                    </div>
                    <div className="col-md-12">
                        <div className="mouse_scroll">

                            <div className="mouse">
                                <div className="wheel"></div>
                            </div>
                            <div>
                                <span className="m_scroll_arrows unu"></span>
                                <span className="m_scroll_arrows doi"></span>
                                <span className="m_scroll_arrows trei"></span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <SearchEng />
        <section className="flightPack flightoffer">
            <div className="container dest">
                <div className="row">
                    <div className="col-md-9">
                        <Table bordered hover>
                            <tbody>
                                <tr>
                                    <td><img src={MH} alt="MH" className="tairLogo" /></td>
                                    <td>LONDON(All)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>JAKARTA(CGK)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 495.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={CX} alt="CX" className="tairLogo" /></td>
                                    <td>BIRMINGHAM(BHX)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>JAKARTA(CGK)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 515.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={TK} alt="TK" className="tairLogo" /></td>
                                    <td>DUBLIN(DUB)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>JAKARTA(CGK)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 545.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={TG} alt="TG" className="tairLogo" /></td>
                                    <td>EDIBURGH(EDI)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>JAKARTA(CGK)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 565.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={EK} alt="EK" className="tairLogo" /></td>
                                    <td>GLASGOW(GLA)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>JAKARTA(CGK)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 595.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={QR} alt="QR" className="tairLogo" /></td>
                                    <td>MANCHESTER(MAN)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>JAKARTA(CGK)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 615.45</button></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="col-md-3"><img src={FlightOffer} alt="FlightOffer" className="FlightOffer" /> </div>
                </div>
                <div className="row page alignLeft">
                    <div className="col-md-12">
                        <h3>Book Cheap Flight Tickets with Fly2Go.</h3>
                          </div>

                </div>
            </div>
        </section>
    </>
    )
}
