import React from 'react';
import { MetaTags } from 'react-meta-tags';
import { Table } from 'react-bootstrap';
import HomeHeroBanner from "../assets/banner/Johannesburg.jpg";
import FlightOffer from "../assets/images/FlightOffer.png";
import flightiCON from "../assets/Airline/flight.png";
import EK from "../assets/Airline/EK.png";
import BA from "../assets/Airline/BA.png";
import QR from "../assets/Airline/QR.png";
import TK from "../assets/Airline/TK.png";
import KL from "../assets/Airline/KL.png";
import AF from "../assets/Airline/AF.png";
import SearchEng from '../Company/SearchEng';
export default function Johannesburg() {
    return (
        <>
            <div className="HeroBannerHome" style={{ backgroundImage: `url(${HomeHeroBanner})` }}>
            <MetaTags>
  <title>Get best deal for airfare and cheap flight tickets from London to Johannesburg with Fly2go.Call us on 0208-095-0550</title>
  <meta name="description" content="Flight tickets, Holidays Packages and best available Airfare deal in the reasonable price for Johannesburg is from UK .Call Fly2go at 0208 095 0550 for Cheap flights tickets at reasonable cost. Find discounted airfare offers and amazing experience." />
  <meta name="Keywords" content="Johannesburg, cheap flight tickets, packages, Africa, Budget Ticket to Africa, Trips to Africa," />
  <meta name="robots" content="all" />
  <meta name=" author" content=" Fly2go " />
  <meta name="Language" content="English" />
  <meta name="distribution" content="Global" />
  <meta name="Robots" content="INDEX, FOLLOW" />
  <meta name="country" content="United Kingdom" />
  <link rel="canonical" href="https://www.fly2go.co.uk/Johannesburg/" />
</MetaTags>
                <div className="container dest">
                    <div className="row">
                        <div className="col-md-12 headertext">
                            <h1>Now Book Your Cheap Flight Tickets to <strong>Johannesburg.</strong></h1>
                            <p>Discounted Worldwide Tickets with Fly2Go.</p>
                        </div>
                        <div className="col-md-12">
                            <div className="mouse_scroll">

                                <div className="mouse">
                                    <div className="wheel"></div>
                                </div>
                                <div>
                                    <span className="m_scroll_arrows unu"></span>
                                    <span className="m_scroll_arrows doi"></span>
                                    <span className="m_scroll_arrows trei"></span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <SearchEng />
            <section className="flightPack flightoffer">
                <div className="container dest">
                    <div className="row">
                        <div className="col-md-9">
                            <Table bordered hover>
                                <tbody>
                                    <tr>
                                        <td><img src={EK} alt="EK" className="tairLogo" /></td>
                                        <td>LONDON(All)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>JOHANNESBURG(JNB)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 495.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={KL} alt="KL" className="tairLogo" /></td>
                                        <td>BIRMINGHAM(BHX)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>JOHANNESBURG(JNB)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 515.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={AF} alt="AF" className="tairLogo" /></td>
                                        <td>DUBLIN(DUB)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>JOHANNESBURG(JNB)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 545.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={TK} alt="TK" className="tairLogo" /></td>
                                        <td>EDIBURGH(EDI)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>JOHANNESBURG(JNB)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 565.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={BA} alt="BA" className="tairLogo" /></td>
                                        <td>GLASGOW(GLA)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>JOHANNESBURG(JNB)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 595.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={QR} alt="QR" className="tairLogo" /></td>
                                        <td>MANCHESTER(MAN)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>JOHANNESBURG(JNB)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 615.45</button></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-md-3"><img src={FlightOffer} alt="FlightOffer" className="FlightOffer" /> </div>
                    </div>
                    <div className="row page alignLeft">
                        <div className="col-md-12">
                            <h3>Book Cheap Flight Tickets with Fly2Go.</h3>
                            </div>

                    </div>
                </div>
            </section>
        </>
    )
}
