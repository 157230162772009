import React from 'react'
import breadcrumbs from "../assets/images/breadcrumbs.png";
export default function Contact() {
    return (
        <>
        <section className="page_title" style={{ backgroundImage: `url(${breadcrumbs})` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2>Contact Us</h2>             
            </div>
          </div>
        </div>
      </section>
      <div className="container page">
      <div className="row">
    <div className="col">
      <div className="card">
        <div className="card-header bg-primary text-white cbgform"><i className="fa fa-envelope"></i> Contact us. </div>
        <div className="card-body">
        <form action="https://www.fly2go.co.uk/connecting.php" method="post" autocomplete="off">
        <input type="hidden" name="xx_Sb" value="Contact Us" />
            <div className="form-group">
              <label>Name</label>
              <input type="text" name="cfname" className="form-control" placeholder="Enter name" required />
            </div>
            <div className="form-group">
              <label>Email address</label>
              <input type="email" name="cemail" className="form-control" placeholder="Enter email" required />
              </div>
              <div className="form-group">
              <label>Contact No</label>
              <input type="text" name="contactNo" className="form-control" placeholder="Contact No" required />
              </div>
            <div className="form-group">
              <label>Message</label>
              <textarea className="form-control" name="cmessage" rows="3" required></textarea>
            </div><br />
            <div className="mx-auto">
              <button type="submit" name="ContactMe" className="btn btn-primary text-right">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-4">
      <div className="card bg-light mb-3">
        <div className="card-header bg-success text-white text-uppercase Abg"><i className="fa fa-home"></i> Registered Address</div>
        <div className="card-body">
          <p>5 Brayford Square, London, England, E1 0SG</p>        
          
        </div>
      </div>
      <div className="card bg-light mb-3">
        <div className="card-header bg-success text-white text-uppercase Abg"><i className="fa fa-envelope"></i> Email Address</div>
        <div className="card-body">
        <p><strong>Email :</strong>info@fly2go.co.uk</p>      
         
        </div>
      </div>
      <div className="card bg-light mb-3">
        <div className="card-header bg-success text-white text-uppercase Abg"><i className="fa fa-phone"></i> Contact No</div>
        <div className="card-body">
        
          <p><strong>Tel.</strong> 0208 095 0550</p>
        </div>
      </div>
    </div>
  </div>
    </div>
      </>
    )
}
