export function MoviesData() {
    return  [
      {
        "name": "London All (LON)"
      },
      {
        "name": "London Heathrow (LHR)"        
      },
      {
        "name": "Manchester (MAN)" 
      },
      {
        "name": "Birmingham International (BHX)" 
      },
      {
        "name": "London City (LCY)" 
      },
      {
        "name": "London Gatwick (LGW)" 
      },
      {
        "name": "London Luton (LTN)" 
      },
      {
        "name": "London Stansted (STN)" 
      },
      {
        "name": "Newcastle (NCL)" 
      },
      {
        "name": "Newquay Cornwall (NQY)"
      },
      {
        "name": "Aberdeen Dyce (ABZ)"    
      },
      {
        "name": "Belfast City (BHD)"
      },
      {
        "name": "Belfast International (BFS)"
      },
      {
        "name": "Blackpool (BLK)"
      },
      {
        "name": "Bournemouth (BOH)"
      },
      {
        "name": "Bristol (BRS)"
      },
      {
        "name": "Cardiff (CWL)"
      },
      {
        "name": "Coventry (W.Midlands) (CVT)"
      },
      {
        "name": "Doncaster (DSA)"
      },
      {
        "name": "Dublin (DUB)"
      },
      {
        "name": "East Midlands Derby (EMA)"
      },
      {
        "name": "Edinburgh (EDI)"
      },
      {
        "name": "Exeter (EXT)"
      },
      {
        "name": "Glasgow Prestwick (PIK)"
      },
      {
        "name": "Glasgow International (GLA)"
      },
      {
        "name": "Humberside (HUY)"
      },
      {
        "name": "Inverness (INV)"
      },
      {
        "name": "Jersey (JER)"
      },
      {
        "name": "Leeds Bradford (LBA)"
      },
      {
        "name": "Liverpool (LPL)"
      },
      {
        "name": "Norwich International (NWI)"
      },
      {
        "name": "Plymouth (PLH)"
      },
      {
        "name": "Southampton (SOU)"
      },
      {
        "name": "Teeside (MME)"
      },
      {
        "name": "Cork (ORK)"
      },
      {
        "name": "Donegal (CFN)"
      },
      {
        "name": "Dublin DUB"
      },
      {
        "name": "Weston (WST)"
      },
      {
        "name": "Galway (GWY)"
      },
      {
        "name": "Kerry (KIR)"
      },
      {
        "name": "Ireland West (NOC)"
      },
      {
        "name": "Shannon (SNN)"
      },
      {
        "name": "Sligo (SXL)"
      },
      {
        "name": "Waterford (WAT)"
      }
     
    ]
  }
  export function renderMovieTitle(state, val) {
    return (
        state.name.toLowerCase().indexOf(val.toLowerCase()) !== -1
    );
  }