import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import HomeHeroBanner from "../assets/banner/accra.jpg";
import SearchEng from '../Company/SearchEng';
function AirlineDetails() {
  const { airlineName } = useParams();
  const decodedAirlineName = decodeURIComponent(airlineName).replace(/-/g, ' ');

  const [airlines, setAirlines] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [economyAirlines, setEconomyAirlines] = useState([]);
  // Initialize selectedAirline as null
  const [selectedAirline, setSelectedAirline] = useState(null);

  useEffect(() => {
    // Fetch airline data from the AirlineAPI
    axios.get('https://www.worldbesttravel.co.uk/Flight-Admin/AirlineAPI')
      .then(response => {
        const sortedAirlines = sortAirlinesByPrice(response.data);
        setAirlines(sortedAirlines);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (decodedAirlineName) {
      // Find the selected airline from the first API and set it
      const airlineFromFirstAPI = airlines.find(airline => airline.AirlineName === decodedAirlineName);
      setSelectedAirline(airlineFromFirstAPI);
    }
  }, [decodedAirlineName, airlines]);

  useEffect(() => {
    if (selectedAirline) {
      // Fetch additional airline details from the EconomyAirlineAPI based on the selected airline's code
      axios.get(`https://www.worldbesttravel.co.uk/Flight-Admin/EconomyAirlineAPI?AirlineCode=${selectedAirline.AirlineCode}`)
        .then(response => {
          setEconomyAirlines(response.data);
        })
        .catch(error => {
          console.error('Error fetching economy airline data:', error);
        });
    }
  }, [selectedAirline]);

  const sortAirlinesByPrice = (airlines) => {
    return airlines.slice().sort((a, b) => a.Price - b.Price);
  };

  return (
    <>
      <div className="HeroBannerHome" style={{ backgroundImage: `url(${HomeHeroBanner})` }}>
        <div className="container dest">
          <div className="row">
            <div className="col-md-12 headertext">
              <h1>Book Your Cheap Flight Tickets with {decodedAirlineName}</h1>
              <p>Discounted Worldwide Tickets with Fly2Go.</p>
            </div>
            <div className="col-md-12">
              <div className="mouse_scroll">
                <div className="mouse">
                  <div className="wheel"></div>
                </div>
                <div>
                  <span className="m_scroll_arrows unu"></span>
                  <span className="m_scroll_arrows doi"></span>
                  <span className="m_scroll_arrows trei"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SearchEng />

      <section className='pagebg'>
        <div className="container flightExplore">
          <h2><strong>{decodedAirlineName} Airline</strong></h2><br /><br />
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <div className="col-lg-12 col-md-12 flight-results">
              <ul>
                {airlines.map(airline => (
                  <div key={airline.AirlineCode}>
                    {airline.AirlineName === decodedAirlineName && (
                      <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        <tbody>
                          <tr>
                            <td valign="middle" align="center" className="p20 airlogobg">
                              <img src={`https://www.worldbesttravel.co.uk/FlightAirline/${airline.AirlineCode}.png`} alt={airline.AirlineName} />
                            </td>
                            <td className="p50" align="left">
                              <p className="floc"><strong>{airline.Origin}</strong> <img src="https://www.worldbesttravel.co.uk/FlightBanner/arr-dep-icon.png" className="imgicon" /> <strong>{airline.Destination}</strong></p>
                              <p className="fdate"><strong>Airline:</strong> {airline.AirlineName}</p>
                              <p className="fdate"><strong>Class:</strong> {airline.Cabin}</p>
                            </td>
                            <td className="farebg p30">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                  <tr>
                                    <td>
                                      <div className="ffare"><span>From</span> £ {airline.Price}<sup>*</sup></div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p>Call our travel expert</p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <a href="tel:0208 095 0550"><i className="la la-phone-square"></i> 0208 095 0550</a>
                                      <p>24 hours a day/7 days a week</p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                ))}
                {airlines.every(airline => airline.AirlineName !== decodedAirlineName) && (
                  <p>Airline not found.</p>
                )}
              </ul>
            </div>
          )}
        </div>
      </section>

      {/* Display additional information from the EconomyAirlineAPI */}
      <section className='pagebg'>
        <div className="container flightExplore">
          <h2>Additional Information about <strong>{decodedAirlineName} </strong></h2><br /><br />
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <div className="col-lg-12 col-md-12 flight-results">
              <ul>
                {economyAirlines.map(economyAirline => (
                  <div key={economyAirline.AirlineCode}>
                    <p>{economyAirline.AirlineHeading}</p>
                    <p>{economyAirline.AirlineHeadingTwo}</p>
                    <p>{economyAirline.AirlineDes}</p>
                    {/* Display additional economy airline details */}
                  </div>
                ))}
              </ul>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default AirlineDetails;
