import React from 'react';
import breadcrumbs from "../assets/images/breadcrumbs.png";
export default function TermsConditions() {
    return (
        <>
            <section className="page_title" style={{ backgroundImage: `url(${breadcrumbs})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>Terms and Conditions</h2>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container page">
                <div className="row">
                    <div className="col-md-12">
                    <h3>Terms and conditions</h3>
                    <p><a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> WHEN MAKING A BOOKING YOU CONFIRM THAT YOU HAVE AUTHORITY TO ACCEPT THESE TERMS AND CONDITIONS ON BEHALF OF YOURSELF AND ALL MEMBERS OF YOUR PARTY. THIS USER AGREEMENT IS BETWEEN YOU AND <a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> , AND IS GOVERNED BY THE LAWS OF ENGLAND. ALL NEW FLIGHT BOOKINGS ARE PROTECTED UNDER PTS.</p>
                    <p>WORLDBESTTRAVEL LIMITED T/A Fly2go based in United Kingdom and registered with Companies House registration number 04501991,Registered Office : 5 Brayford Square, London, England, E1 0SG United Kingdom . All our products are ATOL protected which means your money is safe and protected.</p>
                    <p>"We/us" Means “WORLDBESTTRAVEL LIMITED T/A FLY2GO”.</p>
                    <p>Your Financial Protection (worldbesttravel Ltd. PTS 5703) When you buy an ATOL protected flight or flight inclusive holiday from us you will receive an ATOL Certificate. This lists what is financially protected, where you can get information on what this means for you and who to contact if things go wrong.</p>
                    <p>Any booking which you make or order which you place shall be an offer by you to purchase the relevant flight, package or other holiday arrangement subject to these terms and conditions and where applicable to the relevant supplier’s standard terms and conditions. A contract is formed when you agree to our booking conditions and make payment. A confirmation will be sent once a booking has been made. By using this website you are agreeing to be bound by these terms and conditions. If you do not agree with any of these terms and conditions you must not use this web site.</p>
                    <h4>Pricing</h4>
                    <p>All our prices are quoted in British Pounds Sterling and subject to availability.</p>
                    <p>All fares quoted at the 'confirmed quotation' stage include pre-payable taxes and applicable transaction fees. These are added together to form your final quotation.</p>
                    <p>Please note that for some destinations a departure and/ or arrival tax is payable locally. It is the passenger's responsibility to pay such taxes, and <a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> accepts no responsibility for denied boarding resulting from failure/ inability to pay such taxes.</p>
                    <p>The price you pay for your tickets is likely to include a booking/ transaction fee made by <a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a>. This is our fee for making and administering your booking, and is non-refundable.</p>
                    <p>Service fees are also charged for other forms of administration, including, but not limited to, reservation changes and refund applications.</p>
<p>For our 'Special Fares' for scheduled flights, these are subject to verification by our ticketing staff. Should we be unable to honour the fare quoted, we will endeavour to notify you within three working days.</p>
<p>Where we book a product priced in a different currency, we reserve the right to include an additional amount to cover the costs of currency conversion and exchange rate fluctuations.</p>
<h4>Making a booking / booking details</h4>
<p>All travel products and services featured on the site are subject to availability.</p>
<h4>Online Booking</h4>
<p>If you book online, you must provide us with all information which we require. You must also ensure that the credit or debit card you are using is your own (or, subject to our agreement, if it is a third party's you have their express authorisation, to use their credit or debit card) and that sufficient funds are available to cover the cost of the arrangements which you book with us.</p>
<p>When we receive and accept your booking we will send you a booking confirmation e-mail and debit payment from you. We do not make any representation or warranty as to the availability of any package holiday, flight or Individual Components nor that our booking services are free from infection of viruses or anything else that has a contaminating or destructive effect on your property.</p>
<h4>Bookings made by telephone</h4>
<p>If you make a booking by telephone you must provide us with all information which we require. You must also ensure that all information which you provide is accurate and that the credit or debit card you are using is your own or, subject to our agreement,if it is a third party's you have their express authorisation to use their credit or debit card and that sufficient funds are available to cover the cost of the arrangements which you book with us.</p>
<p>If we accept your booking, we shall debit payment from you and send you a confirmation .From this point cancellation charges will apply: Please note the details of phone booking always confirmed in writing to avoid any mistake. As soon as you receive the confirmation request, please check the details carefully and inform us immediately if anything appears to be incorrect as it may not be possible to make changes later</p>
<p>Once you have confirmed these details we will proceed to confirm the booking with the supplier/s.</p>
<h4>Contract</h4>
<p>We act as a disclosed agent for third party suppliers, such as airlines, tour operators, car hire companies, hotel companies, consolidators and insurance companies. This means that, when you make a booking, the contract for the product is between you and the supplier. As such, the supplier's booking conditions (including airlines' conditions of carriage) apply to your booking, in addition to those set out here. Please make sure that you have read the supplier's conditions before completing your transaction with us, as they do contain important information about your booking. (You can easily request a copy of the supplier's conditions from us – see 'Contacting <a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> ' below). Please read your ticket wallet/ travel documents for the airline's conditions of carriage.</p>
<p>In a small number of cases, for some products, we act as principal; in these cases the contract for the product is between you and us. We will let you know in instances where we act as principal.</p>
<p>All travel arrangements which we provide or which are sold through us are not an offer by us to sell any travel arrangements, but an invitation to you to make an offer to the suppliers of the arrangements. We are free to accept that offer on behalf of those suppliers or to reject it.</p>
<h4>Liability Protection</h4>
<p>As agent we accept no responsibility for the acts or omissions of the supplier, or for the products and services provided by them. However, we may still be liable to you if we have been negligent, have misrepresented important information.</p>
<h4>Your Obligations</h4>
<p>You agree to be bound by the following obligations, including without limitation: You accept financial responsibility for all transactions made under your name or account. In order to make a purchase you must be at least 18 years old, be purchasing for yourself and have the legal capacity to make the transaction. In case you making booking for a group/part/family will responsible for payment of all.</p>
<p>You must make sure that all the information you provide to us is true and accurate. (Please ensure that you notify us in writing immediately of any change to your address, email address or telephone number).</p>
<p>Failure to supply correct and complete credit or debit card details, including cardholder name/ billing address, may result in delays to the issue of your tickets, increases in the fare, or at worst cancellation of the booking, so please ensure that the details you give match those on the card/ statement. You must not use the website for speculative, false or fraudulent bookings.</p>
<h4>Supplier Conditions</h4>
<p>You are responsible for complying with any airline's terms, for example in relation to check-in times, reconfirmation of flights and other matters. <a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> accepts no responsibility for bookings cancelled due to non-compliance with the airline's rules.</p>
<p>For scheduled flight tickets there are additional terms which apply to each fare. These terms may include, for example, conditions relating to changes, refunds, minimum and maximum duration of stay. Generally, the more flexible the ticket, the more expensive it is likely to be and you need to take various points into consideration before deciding between the fares on offer.</p>
<p>The flights confirmed on your ticket must be used in order of sequence (in the order set out in your itinerary). If this requirement is not met, the airline may cancel any onward flights, and you are unlikely to receive any refund.</p>
<p>Some airlines require you to reconfirm each flight 72 hours prior to departure, and may cancel your flight if you do not do so. If you fail to reconfirm you may be refused permission to board the aircraft and you are unlikely to receive any refund. In any case, <a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> strongly recommends that you reconfirm all flights in this way in case of last minute schedule changes.</p>
<h4>Information on the Site</h4>
<p><a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> does not guarantee that information on the site (including without limitation prices, descriptions or dates) is free from errors or omissions, or that it is suitable for your intended use. We will use all reasonable endeavors to correct any errors or omissions as soon as practicable once they have been drawn to our attention.</p>
<p><a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> offers all general information on the site for guidance only. We reserve the right to change at any time any aspect of the site or its content, including the availability of any suppliers, features, information or content.</p>
<h4>Disclaimer</h4>
<p>Please note that all HTML mark up codes, Images and logos contained on this website, is owned by , its affiliates, and/or their suppliers, and is protected by copyright laws, in case you find any content on our website which violates your privacy or any copyright laws then report us on sales@Fly2go.co.uk or call us on 0208 095 0550, if we find your claim legitimate then we will remove the reported content immediately.</p>
<h4>Links</h4>
<p>The <a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> website contains links to websites owned and operated by third parties. Such linked websites are not controlled or maintained by us; as such <a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> has no responsibility in relation to the accuracy, completeness and quality of the information contained within them.</p>
<p><a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> shall not be liable for any loss or damage caused by use of or reliance upon any content, goods or services available on linked websites.</p>
<h4>Denied Boarding, Flight Delays and Cancellations</h4>
<p>Details of these rights are available from airlines and are also displayed at European airports.</p>
<p>Any payment/ reimbursement in such cases is the responsibility of the airline.</p>
<h4>Community List</h4>
<p>Under European regulations we are required to bring to your attention the existence of a Community List of air carriers which are subject to an operating ban within the EU. The list can be viewed at <a href="https://transport.ec.europa.eu/index_en" target="_blank">www.ec.europa.eu/transport/air-ban/list_en.htm</a>.</p>
<h4>Special Requests</h4>
<p>We are happy to forward details of any additional requirements you may have (for example seating or diet preferences, assistance, etc.) to the relevant travel supplier. These are treated as special requests, and as such fulfilment of these requests cannot be guaranteed.</p>
<h4>Pregnancy</h4>
<p>If you will be traveling while pregnant, please note that regulations apply to the carriage of passengers who are more than a certain number of weeks pregnant at the time of travel. These regulations vary between airlines, so it is your responsibility to check the airline's policy before making a booking.</p>
<p>In some cases travel may not be permitted, or you may be required to travel with a doctor's letter, or even obtain a medical clearance from the airline.</p>
<p>You must ensure that your travel insurance covers you if you are pregnant. We recommend that you also confirm with your doctor before booking that you are fit to travel.</p>
<h4>Travelling with Infants</h4>
<p>Airlines require that infants must be of a minimum age (typically six weeks) before they will be permitted to travel. Please ensure that you are aware of the airline's policy before making a booking.</p>
<p>Infants must sit on an adult's lap or occupy an infant seat – please contact the airline you are traveling with for details of appropriate seats.</p>
<p>To qualify for an infant fare, the infant must generally be under two years old on the date of return travel. Children aged two years or above must occupy a seat.</p>
<h4>Paying for your booking</h4>
<p>Payment can be made by various Debit / Credit cards mentioned on site , by E commerce link . <a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> may be required to pass your card details to the relevant supplier for fulfilment of the booking.</p>
<p>Scheduled flights & webfares: Full payment is required at the time of booking, by debit or credit card. We reserve the right to apply a surcharge for all credit card transactions. We reserve the right to cancel bookings before or after ticket issue if payment is declined by the card issuer, or if incorrect card details or billing information have been supplied.</p>
<p>will not be liable for any increase in fare due to payment failure.</p>
<p>Further, in an effort to minimise the effects of credit card fraud, we reserve the right to carry out random checks, including checks of the electoral roll, and may request you to either fax or email to us proof of your address and a copy of the credit/ debit card and recent statement, or a copy of the cardholder's and/or passenger's passport/s, before issuing any tickets. We reserve the right to insist that travel documents be sent to the billing address of the card used to pay for the booking. <a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> reserve the right to pass on any charges relating to card chargebacks. As a British company it is not always possible for us to successfully complete the necessary security and identity checks on cards which are registered to billing addresses outside the United Kingdom. If you make a booking using a credit card registered outside the UK, we reserve the right to reject your booking if we are unable to satisfactorily complete the relevant checks.</p>
<h4>Managing your booking</h4>
<p>Please ensure that all your travel, passport, visa and insurance documents are in order and that you arrive in plenty of time for checking in at the airport. It may be necessary to reconfirm your flight with the airline prior to departure. Please ask us for details at least 72 hours before your outbound flight. You should take a note of any reference number or contact name when reconfirming. If you fail to reconfirm you may be refused permission to board the aircraft and you are unlikely to receive any refund.</p>
<h4>Cancellations / Amendments by You</h4>
<p>Any cancellation or amendment you wish to make to your booking will be subject to the relevant supplier's conditions. Some tickets are non-refundable and/ or non-changeable; in other cases it may be possible to amend or cancel your booking subject to a penalty charge.</p>
<p>It must be emphasized that the vast majority of airline tickets are non-transferable, and as such name changes are not normally permitted. It is therefore essential that you enter passengers' names as per passport at the time of booking. For online scheduled flight bookings, the rules regarding cancellations and amendments for the fare purchased are shown during the booking process, please ensure you read these carefully.</p>
<p><a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a>, as agents for the airlines/consolidator must abide by the airlines' terms and conditions – we are unable to deviate from the fare rules/ supplier's conditions. Where changes or refunds are permitted, <a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> will apply administration fees (in line with our current rates) in addition to any fees charged by the supplier, if you ask us to amend or cancel your booking. This is to cover the costs we incur in administering cancellations and amendments.</p>
<p>Any request to amend or cancel a reservation must be notified to <a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> in writing, and can not be actioned until received by us. Requests received outside our opening hours can not be processed until we reopen the next working day, and if we need to contact the travel supplier to effect the change/cancellation, until we are able to contact them.</p>
<h4>Force Majeure</h4>
<p>We accept no responsibility for and shall not be liable in respect of any loss or damage or alterations, delays or changes arising from unusual and unforeseen circumstances beyond our control, such as war or threat of war, riot, civil strife, industrial dispute including air traffic control disputes, terrorist activity, natural and nuclear disaster, fire or adverse weather conditions, technical problems with transport, closure or congestion of airports or ports, cancellations of schedules by scheduled airlines. You can check the current position on any country by contacting the Foreign and Commonwealth Office.</p>
<h4>Cancellation</h4>
<p>Admin fees of £75 per passenger will be charged as cancellation penalty in case of pre/post ticket issuance on top of airline penalty.</p>
<p>If you wish to cancel your booking and attempt to claim a refund, please telephone/ email us in the first instance, so we can advise you if the conditions of your ticket permit any refund. If you wish to proceed with the cancellation, you must notify us in writing. (If you do not advise us of your intention to cancel a booking before the scheduled departure time, or do not check-in, this will be recorded by the airline as a 'no show' and is likely to result in the forfeit of all monies paid). Where a refund is permitted, this may take some time, typically 10-16 weeks, to be authorized by the airline. Once authorized, any refund will be made to the debit or credit card used to make the original booking. It is vital that you advise us when you cancel the booking if that card is no longer valid.</p>
<p>Any refund made will be net of any cancellation charge from the airline or tour operator. Many airlines also charge an additional fee to process refunds. Refunds of any kind will also be subject to <a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a>.co.uk usual administration fees. Booking fees, credit card charges, postage costs and any amendment fees you may have paid for any previous changes are also non-refundable.</p>
<p>In the case of non-refundable scheduled flight tickets, it may be possible to claim back any unused taxes. Please note that not all taxes are refundable. Some airlines do make a fee for processing such requests, and in some cases the charge exceeds what you would get back. Please ask for details when you cancel your booking.</p>
<h4>Amendment</h4>
<p>If you wish to change your booking, please telephone/ email us in the first instance, so we can advise you if the conditions of your ticket permit any changes. If a change is permitted, we may ask you to confirm in writing that you wish to change the booking.</p>
<p>Any changes are subject to availability, limitations and restrictions of the relevant travel supplier.</p>
<p>If a change increases the cost of your booking, you will need to pay such extra costs.</p>
<p>Changes of any kind will also be subject to <a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> 's usual administration fees.</p>
<h4>Cancellations / Amendments by the Travel Supplier</h4>
<p>Airlines reserve the right to make time changes, or in rare cases, to cancel flights, for operational reasons. Whilst <a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> is not responsible for, and has no control over, such changes, we will do our best to assist when such situations arise.</p>
<p>In the unlikely event that your flight is cancelled by the airline or tour operator, your rights and remedies will be governed by the supplier's conditions/ airline's conditions of carriage. As a result you may be entitled to:</p>
<p>(a) carriage on another flight with the same airline without additional costs;<br />
  (b) re-routing to your destination with another carrier without additional costs;<br />
  (c) some other right or remedy.</p>
<p>In the event of schedule changes made prior to commencement of your journey, it is not always necessary to have your tickets reissued or revalidated, but we will advise you should this be necessary. <a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> take no responsibility for any flight rescheduling en route.</p>
<h4>Insurance</h4>
<p>Many suppliers require you to take out travel insurance as a condition of booking with them. In any case, we strongly recommend that all our customers arrange adequate travel insurance for the duration of the trip, since circumstances may arise where neither Wayfarers nor the supplier are liable.</p>
<p>It is recommended that insurance is taken immediately upon making the booking, in order to cover you and your party against the cost of cancellation by you; the cost of assistance (including repatriation) in the event of accident or illness; loss of baggage and money; and other expenses. In most cases cancellation fees will apply if you need to cancel your booking before you travel.</p>
<p><a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> does sell travel insurance, and will be pleased to quote a premium for your journey on request. If we have issued your policy please check it carefully to ensure that all the details are correct and that all relevant information has been provided by you (eg. pre-existing medical conditions). Failure to disclose relevant information will affect your insurance. Please read the policy carefully to ensure that this is suitable and adequate for your needs.</p>
<h4>Tickets</h4>
<p>The type of tickets which will be issued for your booking depends on the airline and route you are booking. The majority of airlines are increasingly issuing electronic tickets, and <a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> will issue electronic tickets whenever the itinerary permits (airlines now insist that e-tickets are issued in these circumstances). For charter flights, paper tickets will be sent by normal post.</p>
<h4>Lost Tickets</h4>
<p>If you lose your paper tickets, you must notify us as soon as possible. It may be possible to reissue them for a fee (depending on the airline's/ supplier's rules). If so, the cost may depend on the circumstances of the loss and how close to the departure date you discover it. In all cases, the charge, and procedure to be followed, will vary according to the airline's policy.<a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> reserves the right to charge fees to cover the administration costs of this.</p>
<p>However, not all tickets can be reissued, in which case you may need to purchase a completely new ticket. If a lost ticket can not be reissued, a refund can be requested from the airline: any refund is at the airline's sole discretion and is not guaranteed. Such refunds may take up to a year to be authorised by the airline.</p>
<h4>Non-Delivery / Non-Receipt of Tickets</h4>
<p>It is your responsibility to advise us if you do not receive your tickets/ e-tickets.</p>
<p><a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> can not accept responsibility if you fail to receive your e-tickets due to providing an inaccurate email address or your junk email settings. We recommend that you add the respective agent email address / info@Fly2go.co.uk> to your safe list.</p>
<h4>Passport / Visas / Health Requirements</h4>
<p>It is your responsibility to ensure that you understand and comply with all the passport, visa and health requirements of all the countries involved in your itinerary (including those that you transit).</p>
<p>It is your responsibility to ensure that you are in possession of a valid passport for your journey. Your passport must also be legible and intact. When making your booking you must ensure that the names you provide match those shown on the passengers' passports. Most countries require that your passport is valid for a period of at least six months after your return travel date: we recommend that you check with the embassy to confirm exact requirements.</p>
<p>It is strongly recommended that children hold their own individual passports; where a child is still included on a parent's passport you are advised to check that this will be suitable for the destination you are visiting before making a booking. Many countries still require passengers to obtain a visa, and in some cases transit visas may be required for countries which you pass through en route to your destination (even when you do not leave the aircraft). <a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> can provide general information about the passport and visa requirements for your trip. Alternatively, for the most up to date information, we recommend that you contact the embassies of the countries you are travelling to/ through. We recommend that you do this well in advance of travel, as visas for certain countries can take some time to obtain.</p>
<p>Some countries also have additional immigration requirements, for example South Africa requires passengers to have at least 2 blank pages in their passports. For travel to the USA, a machine-readable passport is required, among other requirements, details of which may be found at <a href="https://uk.usembassy.gov/" target="_blank">www.usembassy.org.uk</a>. Most destinations will require proof of return travel. Please take special note that for all air travel within the British Isles, airlines require photographic identification of a specific type. Please check all details in respective country website.</p>
<p>Regarding health, you are strongly advised to check with your GP prior to travel for up to date information regarding vaccinations which may be required or recommended for your destination/s. Please note that some countries may require proof of certain vaccinations as a condition of entry. We can provide general information about any health formalities required for your trip but you should check with your own doctor for your specific circumstances.</p>
<p>Please note that health and immigration requirements can change at short notice. Neither <a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> nor the suppliers can accept any responsibility if you are denied boarding or are deported due to failure to comply with the above. You will be responsible for any costs you or <a href="https://fly2go.co.uk/" target="_blank">Fly2go.co.uk</a> incur as a result of such failure.</p>
<h4>Service Charges</h4>
<p>We apply a service charge for certain services we provide. These charges are non-refundable. These are in addition to any fees charged by the supplier.</p>

                    </div>
                </div>
            </div>
        </>
    )
}
