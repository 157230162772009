import React from 'react';
import { MetaTags } from 'react-meta-tags';
import breadcrumbs from "../assets/images/breadcrumbs.png";
export default function About() {
    return (
        <>
        <section className="page_title" style={{ backgroundImage: `url(${breadcrumbs})` }}>
        <MetaTags>
        <title>About</title>
        </MetaTags>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2>About Us</h2>             
            </div>
          </div>
        </div>
      </section>
      <div className="container page">
      <div className="row">
        <div className="col-md-12">
          <h3>Fly2go</h3>
         <p>Travelling is one of the best ways to feel relaxed, detox and recuperate from the stress of everyday life. Planning a holiday trip is no less than an adventure or a thrilling exercise but they are not always pleasant as imagined. The task of going on a holiday trip turns from exciting to tedious as the vacationers need to juggle various destinations, need to search for pocket-friendly accommodations, search for the best deals on flights and what to do when they get there. That is when you need to seek the help of experts from the best travel websites to streamline your travel planning process. </p>
         <p>With this aim, Fly2go was established by a family of passionate travelers to reveal a world of greater travel possibilities. Based in the United Kingdom and registered with Companies House with registration number 04501991 the company has proved its mettle and has grown in size, reputation and abilities. Blessed with a team of experts and equipped with state-of-art technology through this website we hope to makethe travel planning process smoother for you and inspire you to explore the best places with your family and loved ones. </p>
         <p>With great financial security of all the financial transactions by ATOL, easy navigation, user-friendly customer support, detailed itineraries and travel guides and simplifying the flight booking experience, Fly2go wants you to celebrate the destinations that surprise you and plan your trips that won’t break the bank.</p>
         <p>We are not just a business but a family of passionate travel experts who want to help you to enjoy the best holiday experience when you book with us. From helping you to find the best deals on flights to realize your dreams of travelling to faraway places we help you with the tools and features to help you to search for flights and find the most suitable deals.</p>
         <p>Let’s get going together. We are ready call us at 0208 095 0550 when you are. </p>
         </div>
      </div>
    </div>
      </>
    )
}
