import React from 'react';
import { MetaTags } from 'react-meta-tags';
import breadcrumbs from "../assets/images/breadcrumbs.png";

function Success() {
  return (
    <>
     <section className="page_title" style={{ backgroundImage: `url(${breadcrumbs})` }}>
        <MetaTags>
        <title>Payment Received</title>
        </MetaTags>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2>Payment</h2>             
            </div>
          </div>
        </div>
      </section>
      <div className="container page paymenttext">
      <div class="row">
   <div class="col-lg-12 col-md-12 offer-banner">
   <h2>PAYMENT RECEIVED </h2>
  <h5>One of our travel expert will get back to you shortly</h5> 
	   <h3>THANK YOU !</h3>
   <div class="ticmark"><img src="https://www.worldbesttravel.co.uk/images/ticmark.png" /></div>
    <br />
          
    <div>
    <a href="tel:0208 095 0550" class="user-action btn btn-primary btn-inverse">0208 095 0550</a><br />
    </div> <br />
   </div> 
   </div>
    </div>
    </>
  )
}

export default Success
