import React from 'react';
import breadcrumbs from "../assets/images/breadcrumbs.png";
import { Accordion } from "react-bootstrap";
export default function FAQ() {
    return (
       <>
        <section className="page_title" style={{ backgroundImage: `url(${breadcrumbs})` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2>FAQ</h2>             
            </div>
          </div>
        </div>
      </section>
      <div className="container page">
      <div className="row">
        <div className="col-md-12">
        <Accordion defaultActiveKey="0">
  <Accordion.Item eventKey="0">
    <Accordion.Header>How do I make bookings with Fly2GO ?</Accordion.Header>
    <Accordion.Body><p>Booking the flight for your desired destination on Fly2Go  is really quick and convenient. All you have to do is just visit our website Fly2Go  and use the filters to find the best flights possible for your travel dates. If you face any problem while making the bookings you can call us directly at our customer care number 0208 095 0550 and one of our executives will assist you.</p></Accordion.Body>
  </Accordion.Item>
   
</Accordion>
         </div>
      </div>
    </div>
       </>
    )
}
