import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom'; 
import HomeHeroBanner from "../assets/banner/accra.jpg";
import SearchEng from '../Company/SearchEng';
const AirCanadaAirline = () => {
  // State for flight data
  const [flightData, setFlightData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  useEffect(() => {
    fetchFlightData();
  }, []);

  const fetchFlightData = async () => {
    try {
      const response = await axios.get('https://fly2go.co.uk/Admin/AirlineAPI?AirlineCode=ET');
      setFlightData(response.data);
      setIsLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      console.error('Error fetching flight data:', error);
    }
  };

  // State for fare data
  const [fareData, setFareData] = useState([]);

  useEffect(() => {
    fetchFareData();
  }, []);

  const fetchFareData = async () => {
    try {
      const response = await axios.get('https://fly2go.co.uk/Admin/EconomyAirlineAPI?AirlineName=ET');
      setFareData(response.data);
    } catch (error) {
      console.error('Error fetching fare data:', error);
    }
  };

  return (
    <>
     <div className="HeroBannerHome" style={{ backgroundImage: `url(${HomeHeroBanner})` }}>
        <div className="container dest">
          <div className="row">
            <div className="col-md-12 headertext">
              <h1>Book Your Cheap Flight Tickets with Ethiopian Airways</h1>
              <p>Discounted Worldwide Tickets with Fly2Go.</p>
            </div>
            <div className="col-md-12">
              <div className="mouse_scroll">
                <div className="mouse">
                  <div className="wheel"></div>
                </div>
                <div>
                  <span className="m_scroll_arrows unu"></span>
                  <span className="m_scroll_arrows doi"></span>
                  <span className="m_scroll_arrows trei"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SearchEng />
      <section className='pagebg'>
        <div className="container flightExplore">
          <h2>Book flight with ethiopian airways</h2><br /><br />
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <div className="col-lg-12 col-md-12 flight-results">
              <ul>
                {flightData.map((flight, index) => (
                  <div key={index}>
                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tbody>
                        <tr>
                          <td valign="middle" align="center" className="p20 airlogobg">
                            <img src={`https://www.worldbesttravel.co.uk/FlightAirline/${flight.AirlineCode}.png`} alt={flight.AirlineName} />
                          </td>
                          <td className="p50" align="left">
                            <p className="floc"><strong>{flight.Origin}</strong> <img src="https://www.worldbesttravel.co.uk/FlightBanner/arr-dep-icon.png" className="imgicon" /> <strong>{flight.Destination}</strong></p>
                            <p className="fdate"><strong>Airline:</strong> {flight.AirlineName}</p>
                            <p className="fdate"><strong>Class:</strong> {flight.Cabin}</p>
                          </td>
                          <td className="farebg p30">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="ffare"><span>From</span> £ {flight.Price}<sup>*</sup></div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p>Call our travel expert</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <a href="tel:0208 095 0550"><i className="la la-phone-square"></i> 0208 095 0550</a>
                                    <p>24 hours a day/7 days a week</p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))}
              </ul>
            </div>
          )}
        </div>
      </section>

      <section className='pagebg'>
        <div className='container airlinepage'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='row'>
                {fareData.map((flight, index) => (
                  <div className='col-md-12' key={index}>
                    <h3>{ReactHtmlParser(flight.AirlineHeading)}</h3>
                    <h4> {ReactHtmlParser(flight.AirlineHeadingTwo)}</h4>
                    <p> {ReactHtmlParser(flight.AirlineDes)}</p>                    
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AirCanadaAirline;
