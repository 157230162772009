import React from 'react';
import { MetaTags } from 'react-meta-tags';
import { Table } from 'react-bootstrap';
import HomeHeroBanner from "../assets/banner/LAX.jpg";
import FlightOffer from "../assets/images/FlightOffer.png";
import flightiCON from "../assets/Airline/flight.png";
import LH from "../assets/Airline/LH.png";
import BA from "../assets/Airline/BA.png";
import UA from "../assets/Airline/UA.png";
import TK from "../assets/Airline/TK.png";
import AA from "../assets/Airline/AA.png";
import DL from "../assets/Airline/DL.png";
import SearchEng from '../Company/SearchEng';
export default function LosAngeles() {
    return (
        <>
        <div className="HeroBannerHome" style={{ backgroundImage: `url(${HomeHeroBanner})` }}>
        <MetaTags>
  <title>Get cheapest available flight deals to Los Angeles from various airports of UK on Fly2go. Call us on 0208-095-0550.</title>
  <meta name="description" content="Avail best offer of the season with American Airline by getting cheapest flight to Los Angeles from UK. We offers you best Last minutes flight deals of the season to Los Angeles. Call us at 0208-095-0550. Find direct flight from London Heathrow to LA in a very reasonable process on Fly2go." />
	<meta name="Keywords" content="Cheap flights to Los Angeles FROM UK, Best deal to Los Angeles, American airline, cheapest flight to Los Angeles, Los Angeles tailor made deal" />
  <meta name="robots" content="all" />
  <meta name=" author" content=" Fly2go " />
  <meta name="Language" content="English" />
  <meta name="distribution" content="Global" />
  <meta name="Robots" content="INDEX, FOLLOW" />
  <meta name="country" content="United Kingdom" />
  <link rel="canonical" href="https://www.fly2go.co.uk/LosAngeles" />
</MetaTags>
            <div className="container dest">
                <div className="row">
                    <div className="col-md-12 headertext">
                        <h1>Now Book Your Cheap Flight Tickets to <strong>Los Angeles.</strong></h1>
                        <p>Discounted Worldwide Tickets with Fly2Go.</p>
                    </div>
                    <div className="col-md-12">
                        <div className="mouse_scroll">

                            <div className="mouse">
                                <div className="wheel"></div>
                            </div>
                            <div>
                                <span className="m_scroll_arrows unu"></span>
                                <span className="m_scroll_arrows doi"></span>
                                <span className="m_scroll_arrows trei"></span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <SearchEng />
        <section className="flightPack flightoffer">
            <div className="container dest">
                <div className="row">
                    <div className="col-md-9">
                        <Table bordered hover>
                            <tbody>
                                <tr>
                                    <td><img src={UA} alt="UA" className="tairLogo" /></td>
                                    <td>LONDON(All)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>LOS ANGELES(LAX)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 515.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={LH} alt="LH" className="tairLogo" /></td>
                                    <td>BIRMINGHAM(BHX)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>LOS ANGELES(LAX)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 525.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={AA} alt="AA" className="tairLogo" /></td>
                                    <td>DUBLIN(DUB)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>LOS ANGELES(LAX)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 565.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={TK} alt="TK" className="tairLogo" /></td>
                                    <td>EDIBURGH(EDI)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>LOS ANGELES(LAX)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 575.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={DL} alt="DL" className="tairLogo" /></td>
                                    <td>GLASGOW(GLA)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>LOS ANGELES(LAX)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 595.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={BA} alt="BA" className="tairLogo" /></td>
                                    <td>MANCHESTER(MAN)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>LOS ANGELES(LAX)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 605.45</button></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="col-md-3"><img src={FlightOffer} alt="FlightOffer" className="FlightOffer" /> </div>
                </div>
                <div className="row page alignLeft">
                    <div className="col-md-12">
                        <h3>Book Cheap Flight Tickets with Fly2Go.</h3>
                          </div>

                </div>
            </div>
        </section>
    </>
    )
}
