import React from 'react'; // Make sure you import React
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'; // Import the necessary components
import Header from './Company/Header';
import Footer from './Company/Footer';
import Selfpayment from './Payment/Selfpayment';
import Success from './Payment/Success';
import Failure from './Payment/Failure';
import Home from './Company/Home';
import About from './Company/About';
import Contact from './Company/Contact';
import FAQ from './Company/FAQ';
import Covid from './Company/Covid';
import TermsConditions from './Company/TermsConditions';
import PriceMatchPromise from './Company/PriceMatchPromise';
import PrivacyPolicy from './Company/PrivacyPolicy';
import Bangkok from './Destination/Bangkok';
import Accra from './Destination/Accra';
import AddisAbaba from './Destination/AddisAbaba';
import Auckland from './Destination/Auckland';
import Barbados from './Destination/Barbados';
import Cairo from './Destination/Cairo';
import Chicago from './Destination/Chicago';
import Dallas from './Destination/Dallas';
import Dhaka from './Destination/Dhaka';
import HoChMinh from './Destination/HoChMinh';
import Islamabad from './Destination/Islamabad';
import Jakarta from './Destination/Jakarta';
import Johannesburg from './Destination/Johannesburg';
import Kingston from './Destination/Kingston';
import Lagos from './Destination/Lagos';
import LosAngeles from './Destination/LosAngeles';
import Manila from './Destination/Manila';
import Melbourne from './Destination/Melbourne';
import NewYork from './Destination/NewYork';
import Singapore from './Destination/Singapore';
import Sydney from './Destination/Sydney';
import ScrollTop from './Company/ScrollTop';
import CallBack from './Company/CallBack';
import Thanks from './Company/Thanks';
import Popupcall from './Destination/Popupcall';
import Popup from './Destination/Popup';
import Airlines from './Airlines/Airlines';
import Airline from './Airlines/Airline';
import AirlineDetails from './Airlines/AirlineDetails';

import EthiopianAirways from './Airlines/EthiopianAirways';
import QatarAirways from './Airlines/QatarAirways';
import KenyaAirways from './Airlines/KenyaAirways';
import EmiratesAirlines from './Airlines/EmiratesAirlines';
import BritishAirways from './Airlines/BritishAirways';
function App() {

  return (
    <>
      <Router>
        <ScrollTop />
        <Header />
        <Routes>
        <Route path="/" element={<Home />} />  
        <Route path="/About" element={<About />} />
        <Route path="/Self-Payment" element={<Selfpayment />} />  
        <Route path="/Payment-Success" element={<Success />} />  
        <Route path="/Payment-Failure" element={<Failure />} />  
        <Route path="/Contact" element={<Contact />} />  
        <Route path="/FAQ" element={<FAQ />} />  
        <Route path="/Covid" element={<Covid />} />  
        <Route path="/TermsConditions" element={<TermsConditions />} />  
        <Route path="/PriceMatchPromise" element={<PriceMatchPromise />} />  
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />  
        <Route path="/Accra" element={<Accra />} />  
        <Route path="/AddisAbaba" element={<AddisAbaba />} />  
        <Route path="/Auckland" element={<Auckland />} />  
        <Route path="/Bangkok" element={<Bangkok />} />  
        <Route path="/Barbados" element={<Barbados />} />  
        <Route path="/Cairo" element={<Cairo />} />  
        <Route path="/Chicago" element={<Chicago />} />  
        <Route path="/Dallas" element={<Dallas />} />
        <Route path="/Dhaka" element={<Dhaka />} />
        <Route path="/HoChMinh" element={<HoChMinh />} />
        <Route path="/Islamabad" element={<Islamabad />} />
        <Route path="/Jakarta" element={<Jakarta />} />
        <Route path="/Johannesburg" element={<Johannesburg />} />
        <Route path="/Kingston" element={<Kingston />} />
        <Route path="/Lagos" element={<Lagos />} />
        <Route path="/LosAngeles" element={<LosAngeles />} />
        <Route path="/Manila" element={<Manila />} />
        <Route path="/Melbourne" element={<Melbourne />} />
        <Route path="/NewYork" element={<NewYork />} />  
        <Route path="/Singapore" element={<Singapore />} />
        <Route path="/Sydney" element={<Sydney />} />
        <Route path="/CallBack" element={<CallBack />} />
        <Route path="/Popupcall" element={<Popupcall />} />
        <Route path="/Popup" element={<Popup />} />
        <Route path="*" element={<Thanks />} />
        <Route path="/Airlines" element={<Airlines />} />
        <Route path="/Airline" element={<Airline />} />
        <Route path="/airlines/:airlineName" element={<AirlineDetails />} />
          <Route path="/Redirect" element={<RedirectToThanks />} />

          <Route path="/Airlines/Ethiopian-Airways" element={<EthiopianAirways />} />
          <Route path="/Airlines/Qatar-Airways" element={<QatarAirways />} />
          <Route path="/Airlines/Kenya-Airways" element={<KenyaAirways />} />
          <Route path="/Airlines/Emirates-Airlines" element={<EmiratesAirlines />} />
          <Route path="/Airlines/British-Airways" element={<BritishAirways />} />
        </Routes>
        <Footer />
      </Router>
    </>
   
  );
}

function RedirectToThanks() {
  const navigate = useNavigate(); // Use the useNavigate hook
  navigate('/Thanks'); // Redirect using the navigate function
  return null;
}
export default App;
