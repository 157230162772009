import React from 'react';
import breadcrumbs from "../assets/images/breadcrumbs.png";
import CallOffer from "../assets/images/CallOffer.png";
export default function CallBack() {
    return (
        <>
            <section className="page_title" style={{ backgroundImage: `url(${breadcrumbs})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>Request a call back</h2>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container page">
                <div className="row">
                    <div className="col-12 col-md-9">
                        <div className="card">
                            <div className="card-header bg-primary text-white cbgform"><i className="fa fa-user"></i> <strong>Personal Details</strong>
                            </div>
                            <div className="card-body">
                                <div className="row callBack">
                                <form action="https://www.fly2go.co.uk/connecting.php" method="post" autocomplete="off">
                                <input type="hidden" name="xx_Sb" value="Request a call back" />
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>First Name</label>
                                                <input type="text" name="fname" className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Last Name</label>
                                                <input type="text" name="lname" className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Email address</label>
                                                <input type="email" name="email" className="form-control" required />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Phone No</label>
                                                <input type="text" name="cno" className="form-control"  required />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Departure</label>
                                                <input type="text" name="Departure" className="form-control"  required />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Destination</label>
                                                <input type="text" name="Destination" className="form-control"  required />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Hotel Name</label>
                                                <input type="text" name="hname" className="form-control"  required />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>No of passengers</label>
                                                <input type="text" name="nopas" className="form-control"  required />
                                            </div>
                                        </div>
                                         
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Message</label>
                                                <textarea className="form-control" name="message" rows="3" required></textarea>
                                            </div>
                                        </div>
                                         
                                        <div className="mx-auto">
                                            <button type="submit" name="CallBack" className="btn btn-primary text-right">Submit Enquiry</button>
                                        </div>
                                        <small>
                                        Your personal information is safe with us and will never be passed on to third parties.<br />
You receive an email acknowledgement after submitting this form and our email newsletter which we send from time to time.
                                        </small>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-3">
                        <div className="card bg-light mb-3">
                            <div className="card-header bg-success text-white text-uppercase Abg"><i className="fa fa-phone"></i> <strong>Request a call back Offer</strong>
</div>
                            <div className="card-body">
                            <img src={CallOffer} alt="CallOffer" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
