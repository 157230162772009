import React from 'react';
import { MetaTags } from 'react-meta-tags';
import { Table } from 'react-bootstrap';
import HomeHeroBanner from "../assets/banner/melbourne.jpg";
import FlightOffer from "../assets/images/FlightOffer.png";
import flightiCON from "../assets/Airline/flight.png";
import EK from "../assets/Airline/EK.png";
import CX from "../assets/Airline/CX.png";
import QR from "../assets/Airline/QR.png";
import SQ from "../assets/Airline/SQ.png";
import KL from "../assets/Airline/KL.png";
import MH from "../assets/Airline/MH.png";
import SearchEng from '../Company/SearchEng';
export default function Melbourne() {
    return (
        <>
        <div className="HeroBannerHome" style={{ backgroundImage: `url(${HomeHeroBanner})` }}>
        <MetaTags>
  <title>Find Cheapest airfare to Australia's various city like Melbourne from UK only on Fly2go. Call us on 0208-095-0550.</title>
  <meta name="description" content="Book Melbourne Flight Ticket online at FLY2GO. Melbourne Flights from UK is all about exploring beautiful experiences with Qantas Airlines in a very cheap airfare. Get discounted business and economy class ticket in a very reasonable price only on FLY2GO. Call us on 0208-095-0550." />
	<meta name="Keywords" content="Best flight tickets to Melbourne from UK, cheap flights tickets to Melbourne, Low cost flight to Australia, Discounted Flights to Melbourne, Discounted Airfare with Qantas Airlines" />
  <meta name="robots" content="all" />
  <meta name=" author" content=" Fly2go " />
  <meta name="Language" content="English" />
  <meta name="distribution" content="Global" />
  <meta name="Robots" content="INDEX, FOLLOW" />
  <meta name="country" content="United Kingdom" />
  <link rel="canonical" href="https://www.fly2go.co.uk/Melbourne" />
</MetaTags>
            <div className="container dest">
                <div className="row">
                    <div className="col-md-12 headertext">
                        <h1>Now Book Your Cheap Flight Tickets to <strong>Melbourne.</strong></h1>
                        <p>Discounted Worldwide Tickets with Fly2Go.</p>
                    </div>
                    <div className="col-md-12">
                        <div className="mouse_scroll">

                            <div className="mouse">
                                <div className="wheel"></div>
                            </div>
                            <div>
                                <span className="m_scroll_arrows unu"></span>
                                <span className="m_scroll_arrows doi"></span>
                                <span className="m_scroll_arrows trei"></span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <SearchEng />
        <section className="flightPack flightoffer">
            <div className="container dest">
                <div className="row">
                    <div className="col-md-9">
                        <Table bordered hover>
                            <tbody>
                                <tr>
                                    <td><img src={EK} alt="EK" className="tairLogo" /></td>
                                    <td>LONDON(All)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>MELBOURNE(MEL)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 595.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={CX} alt="CX" className="tairLogo" /></td>
                                    <td>BIRMINGHAM(BHX)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>MELBOURNE(MEL)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 615.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={QR} alt="QR" className="tairLogo" /></td>
                                    <td>DUBLIN(DUB)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>MELBOURNE(MEL)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 645.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={SQ} alt="SQ" className="tairLogo" /></td>
                                    <td>EDIBURGH(EDI)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>MELBOURNE(MEL)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 695.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={KL} alt="KL" className="tairLogo" /></td>
                                    <td>GLASGOW(GLA)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>MELBOURNE(MEL)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 715.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={MH} alt="MH" className="tairLogo" /></td>
                                    <td>MANCHESTER(MAN)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>MELBOURNE(MEL)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 745.45</button></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="col-md-3"><img src={FlightOffer} alt="FlightOffer" className="FlightOffer" /> </div>
                </div>
                <div className="row page alignLeft">
                    <div className="col-md-12">
                        <h3>Book Cheap Flight Tickets with Fly2Go.</h3>
                         </div>

                </div>
            </div>
        </section>
    </>
    )
}
