import React from 'react';
import { MetaTags } from 'react-meta-tags';
import { Table } from 'react-bootstrap';
import HomeHeroBanner from "../assets/banner/Dhaka.jpg";
import FlightOffer from "../assets/images/FlightOffer.png";
import flightiCON from "../assets/Airline/flight.png";
import BG from "../assets/Airline/BG.png";
import EK from "../assets/Airline/EK.png";
import QR from "../assets/Airline/QR.png";
import TK from "../assets/Airline/TK.png";
import EY from "../assets/Airline/EY.png";
import GF from "../assets/Airline/GF.png";
import SearchEng from '../Company/SearchEng';
export default function Dhaka() {
    return (
        <>
            <div className="HeroBannerHome" style={{ backgroundImage: `url(${HomeHeroBanner})` }}>
            <MetaTags>
  <title>If you want to book cheap flight tickets online and get a discounted rate flight to Dhaka from UK visit Fly2go.Call us on 0208-095-0550.</title>
  <meta name="description" content="Book Dhaka Flight Ticket online at FLY2GO. Dhaka Flights from Uk is all about exploring wonderful experiences with Biman Airlines in a very cheap airfare. Experience the beauty of Bangladesh in very cheap and reasonable price with Biman Bangladesh on FLY2GO. Call us on 0208-095-0550." />
	<meta name="Keywords" content="cheap flight to Dhaka, Biman Bangladesh Airlines, flight ticket to Dhaka, low cost airfare deal" />
  <meta name="robots" content="all" />
  <meta name=" author" content=" Fly2go " />
  <meta name="Language" content="English" />
  <meta name="distribution" content="Global" />
  <meta name="Robots" content="INDEX, FOLLOW" />
  <meta name="country" content="United Kingdom" />
  <link rel="canonical" href="https://www.fly2go.co.uk/Dhaka" />
</MetaTags>
                <div className="container dest">
                    <div className="row">
                        <div className="col-md-12 headertext">
                            <h1>Now Book Your Cheap Flight Tickets to <strong>Dhaka.</strong></h1>
                            <p>Discounted Worldwide Tickets with Fly2Go.</p>
                        </div>
                        <div className="col-md-12">
                            <div className="mouse_scroll">

                                <div className="mouse">
                                    <div className="wheel"></div>
                                </div>
                                <div>
                                    <span className="m_scroll_arrows unu"></span>
                                    <span className="m_scroll_arrows doi"></span>
                                    <span className="m_scroll_arrows trei"></span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <SearchEng />
            <section className="flightPack flightoffer">
                <div className="container dest">
                    <div className="row">
                        <div className="col-md-9">
                            <Table bordered hover>
                                <tbody>
                                    <tr>
                                        <td><img src={BG} alt="BG" className="tairLogo" /></td>
                                        <td>LONDON(All)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>DHAKA(DAC)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 395.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={EK} alt="EK" className="tairLogo" /></td>
                                        <td>BIRMINGHAM(BHX)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>DHAKA(DAC)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 425.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={EY} alt="EY" className="tairLogo" /></td>
                                        <td>DUBLIN(DUB)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>DHAKA(DAC)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 465.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={GF} alt="GF" className="tairLogo" /></td>
                                        <td>EDIBURGH(EDI)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>DHAKA(DAC)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 495.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={TK} alt="TK" className="tairLogo" /></td>
                                        <td>GLASGOW(GLA)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>DHAKA(DAC)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 515.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={QR} alt="QR" className="tairLogo" /></td>
                                        <td>MANCHESTER(MAN)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>DHAKA(DAC)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 545.45</button></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-md-3"><img src={FlightOffer} alt="FlightOffer" className="FlightOffer" /> </div>
                    </div>
                    <div className="row page alignLeft">
                        <div className="col-md-12">
                            <h3>Book Cheap Flight Tickets with Fly2Go.</h3>
                            </div>

                    </div>
                </div>
            </section>
        </>
    )
}
