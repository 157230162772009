import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HomeHeroBanner from "../assets/banner/accra.jpg";
import EthiopianAirways from './EthiopianAirways';
import SearchEng from '../Company/SearchEng';
import closeIcon from "../assets/images/close-mfp.png";
import AirlineOffer from "../assets/images/airlineOffer.jpg";
 
function Airline() {
  const [show, setShow] = React.useState();
  const [contactNumber, setContactNumber] = useState('');
  const [isValid, setIsValid] = useState(true);

  const handleContactNumberChange = (e) => {
    const inputValue = e.target.value;
    // Use a regular expression to check if the input consists of exactly 10 digits
    const isValidNumber = /^\d{10}$/.test(inputValue);
    
    setContactNumber(inputValue);
    setIsValid(isValidNumber);
  };
  return (
    <>
      <div className="HeroBannerHome" style={{ backgroundImage: `url(${HomeHeroBanner})` }}>
        <div className="container dest">
          <div className="row">
          
            <div className="col-md-12">
              <div className="mouse_scroll">

                <div className="mouse">
                  <div className="wheel"></div>
                </div>
                <div>
                  <span className="m_scroll_arrows unu"></span>
                  <span className="m_scroll_arrows doi"></span>
                  <span className="m_scroll_arrows trei"></span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <SearchEng />
      <div className="container flightExplore">
        <h2><strong>Select your Airline</strong></h2><br /><br />
        <div className="row">
          <div className="col-md-2">
            <Link to="/Airlines/Ethiopian-Airways">
              <div className='apLogo'>
                <img src={`https://fly2go.co.uk/FlightAirline/ET.png`} alt="Ethiopian Airlines" />
                <h4>Ethiopian Airlines</h4>
              </div>
            </Link>
          </div>
          <div className="col-md-2">
            <Link to="/Airlines/Qatar-Airways">
              <div className='apLogo'>
                <img src={`https://fly2go.co.uk/FlightAirline/QR.png`} alt="Qatar Airways" />
                <h4>Qatar Airways</h4>
              </div>
            </Link>
          </div>
          <div className="col-md-2">
            <Link to="/Airlines/Kenya-Airways">
              <div className='apLogo'>
                <img src={`https://fly2go.co.uk/FlightAirline/KQ.png`} alt="Kenya Airways" />
                <h4>Kenya Airways</h4>
              </div>
            </Link>
          </div>
          <div className="col-md-2">
            <Link to="/Airlines/Emirates-Airlines">
              <div className='apLogo'>
                <img src={`https://fly2go.co.uk/FlightAirline/EK.png`} alt="Emirates Airlines" />
                <h4>Emirates Airlines</h4>
              </div>
            </Link>
          </div>
          <div className="col-md-2">
            <Link to="/Airlines/British-Airways">
              <div className='apLogo'>
                <img src={`https://fly2go.co.uk/FlightAirline/BA.png`} alt="British Airways" />
                <h4>British Airways</h4>
              </div>
            </Link>
          </div>

        </div>

      </div>
 
    </>
  );
}

export default Airline;
