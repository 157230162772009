import React from 'react';
import { MetaTags } from 'react-meta-tags';
import { Table } from 'react-bootstrap';
import HomeHeroBanner from "../assets/banner/Islamabad.jpg";
import FlightOffer from "../assets/images/FlightOffer.png";
import flightiCON from "../assets/Airline/flight.png";
import EK from "../assets/Airline/EK.png";
import BA from "../assets/Airline/BA.png";
import QR from "../assets/Airline/QR.png";
import KL from "../assets/Airline/KL.png";
import VS from "../assets/Airline/VS.png";
import EY from "../assets/Airline/EY.png";
import SearchEng from '../Company/SearchEng';
export default function Islamabad() {
    return (
        <>
        <div className="HeroBannerHome" style={{ backgroundImage: `url(${HomeHeroBanner})` }}>
        <MetaTags>
  <title>Find Cheap or Business Class Flights in PIA and 130 more international Airlines for Islamabad with Fly2GO. Call us on 0208-095-0550.</title>
  <meta name="description" content="Find the cheapest flight for Islamabad from various airports of UK with best available airfairs. Discover the best Islamabad Flight prices and book your trip with Fly2go now. Call us on 0208-095-0550." />
	<meta name="Keywords" content="Cheap flight ticket, best deal in PIA, cheapest flight to Islamabad, international flight, discounted flight fare to Islamabad" />
  <meta name="robots" content="all" />
  <meta name=" author" content=" Fly2go " />
  <meta name="Language" content="English" />
  <meta name="distribution" content="Global" />
  <meta name="Robots" content="INDEX, FOLLOW" />
  <meta name="country" content="United Kingdom" />
  <link rel="canonical" href="https://www.fly2go.co.uk/Islamabad" />
</MetaTags>
            <div className="container dest">
                <div className="row">
                    <div className="col-md-12 headertext">
                        <h1>Now Book Your Cheap Flight Tickets to <strong>Islamabad.</strong></h1>
                        <p>Discounted Worldwide Tickets with Fly2Go.</p>
                    </div>
                    <div className="col-md-12">
                        <div className="mouse_scroll">

                            <div className="mouse">
                                <div className="wheel"></div>
                            </div>
                            <div>
                                <span className="m_scroll_arrows unu"></span>
                                <span className="m_scroll_arrows doi"></span>
                                <span className="m_scroll_arrows trei"></span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <SearchEng />
        <section className="flightPack flightoffer">
            <div className="container dest">
                <div className="row">
                    <div className="col-md-9">
                        <Table bordered hover>
                            <tbody>
                                <tr>
                                    <td><img src={VS} alt="VS" className="tairLogo" /></td>
                                    <td>LONDON(All)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>ISLAMABAD(ISB)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 395.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={EK} alt="EK" className="tairLogo" /></td>
                                    <td>BIRMINGHAM(BHX)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>ISLAMABAD(ISB)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 425.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={QR} alt="QR" className="tairLogo" /></td>
                                    <td>DUBLIN(DUB)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>ISLAMABAD(ISB)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 465.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={KL} alt="KL" className="tairLogo" /></td>
                                    <td>EDIBURGH(EDI)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>ISLAMABAD(ISB)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 495.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={BA} alt="BA" className="tairLogo" /></td>
                                    <td>GLASGOW(GLA)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>ISLAMABAD(ISB)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 515.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={EY} alt="EY" className="tairLogo" /></td>
                                    <td>MANCHESTER(MAN)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>ISLAMABAD(ISB)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 545.45</button></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="col-md-3"><img src={FlightOffer} alt="FlightOffer" className="FlightOffer" /> </div>
                </div>
                <div className="row page alignLeft">
                    <div className="col-md-12">
                        <h3>Book Cheap Flight Tickets with Fly2Go.</h3>
                         </div>

                </div>
            </div>
        </section>
    </>
    )
}
