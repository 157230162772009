import React from 'react';
import { MetaTags } from 'react-meta-tags';
import { Table } from 'react-bootstrap';
import HomeHeroBanner from "../assets/banner/Kingston.jpg";
import FlightOffer from "../assets/images/FlightOffer.png";
import flightiCON from "../assets/Airline/flight.png";
import AC from "../assets/Airline/AC.png";
import BA from "../assets/Airline/BA.png";
import UA from "../assets/Airline/UA.png";
import AA from "../assets/Airline/AA.png";
import VS from "../assets/Airline/VS.png";
import DL from "../assets/Airline/DL.png";
import SearchEng from '../Company/SearchEng';
export default function Kingston() {
    return (
        <>
        <div className="HeroBannerHome" style={{ backgroundImage: `url(${HomeHeroBanner})` }}>
        <MetaTags>
  <title>Book your Cheap Flight Tickets and Holidays to Kingston and also low cost business class ticket with Fly2go.Call us on 0208-095-0550.</title>
  <meta name="description" content="Discover the exotic location of various places of Caribbean island like Kingston in a very discounted airfare deal and get best deal in Business class. Find amazing Kingston Vacations offers and capture amazing experiences by book your next Caribbean trip with Fly2go now. Call us on 0208-095-0550." />
	<meta name="Keywords" content="Cheap Flight Tickets, Holidays , Airlines Deals, business class ticket to Kingston, best airfare deal to Kingston, last min flight deal to Kingston, direct flight to Kingston, Caribbean holiday packages" />
  <meta name="robots" content="all" />
  <meta name=" author" content=" Fly2go " />
  <meta name="Language" content="English" />
  <meta name="distribution" content="Global" />
  <meta name="Robots" content="INDEX, FOLLOW" />
  <meta name="country" content="United Kingdom" />
  <link rel="canonical" href="https://www.fly2go.co.uk/Kingston" />
</MetaTags>
            <div className="container dest">
                <div className="row">
                    <div className="col-md-12 headertext">
                        <h1>Now Book Your Cheap Flight Tickets to <strong>Kingston.</strong></h1>
                        <p>Discounted Worldwide Tickets with Fly2Go.</p>
                    </div>
                    <div className="col-md-12">
                        <div className="mouse_scroll">

                            <div className="mouse">
                                <div className="wheel"></div>
                            </div>
                            <div>
                                <span className="m_scroll_arrows unu"></span>
                                <span className="m_scroll_arrows doi"></span>
                                <span className="m_scroll_arrows trei"></span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <SearchEng />
        <section className="flightPack flightoffer">
            <div className="container dest">
                <div className="row">
                    <div className="col-md-9">
                        <Table bordered hover>
                            <tbody>
                                <tr>
                                    <td><img src={VS} alt="VS" className="tairLogo" /></td>
                                    <td>LONDON(All)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>KINGSTON(KIN)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 515.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={AC} alt="AC" className="tairLogo" /></td>
                                    <td>BIRMINGHAM(BHX)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>KINGSTON(KIN)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 525.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={BA} alt="BA" className="tairLogo" /></td>
                                    <td>DUBLIN(DUB)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>KINGSTON(KIN)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 565.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={UA} alt="UA" className="tairLogo" /></td>
                                    <td>EDIBURGH(EDI)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>KINGSTON(KIN)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 575.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={AA} alt="AA" className="tairLogo" /></td>
                                    <td>GLASGOW(GLA)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>KINGSTON(KIN)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 595.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={DL} alt="DL" className="tairLogo" /></td>
                                    <td>MANCHESTER(MAN)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>KINGSTON(KIN)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 605.45</button></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="col-md-3"><img src={FlightOffer} alt="FlightOffer" className="FlightOffer" /> </div>
                </div>
                <div className="row page alignLeft">
                    <div className="col-md-12">
                        <h3>Book Cheap Flight Tickets with Fly2Go.</h3>
                         </div>

                </div>
            </div>
        </section>
    </>
    )
}
