import React from 'react';
import { MetaTags } from 'react-meta-tags';
import { Link } from 'react-router-dom';
import bangkokImg from "../assets/Des/bangkokImg.jpg";
import Manilaimg from "../assets/Des/Manila_img.jpg";
import SingaporeImg from "../assets/Des/SingaporeImg.jpg";
import HochminhImg from "../assets/Des/HochminhImg.jpg";
import JakartaImg from "../assets/Des/JakartaImg.jpg";
import islamabadImg from "../assets/Des/islamabadImg.jpg";
import DhakaImg from "../assets/Des/DhakaImg.jpg";
import melbourneImg from "../assets/Des/melbourneImg.jpg";
import DeltaLogo from "../assets/Airline/client-img1.png";
import EmiratesLogo from "../assets/Airline/client-img2.png";
import MalaysiaLogo from "../assets/Airline/client-img3.png";
import QatarLogo from "../assets/Airline/client-img4.png";
import TurkishLogo from "../assets/Airline/client-img5.png";
import BALogo from "../assets/Airline/BALogo.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import SearchEng from './SearchEng';
import HomeSlider from './HomeSlider';
import PopularHoliday from './PopularHoliday';
import closeIcon from "../assets/images/close-mfp.png";
import girlimg from "../assets/images/girl-mfp.png";
import qrf from "../assets/images/qrf.png";
export default function Home() {
  const [show, setShow] = React.useState();
  return (
    <>
   
      <div className="HeroBannerSlider">
     <HomeSlider />   
      </div>      
      <SearchEng />
      <section className="flightExplore">
      <div className="container">
          
          <div className="row">
            <div className="col-lg-12"><br />
              <h2><strong>Why Book With Us?</strong></h2><br />
              </div>
          </div>
          <div className="row">
          
            <div className="col-lg-4">
              <div className="EXFlight">
                <i className="fa fa-lock"></i>
                <h5>100% Secure Booking</h5>
                <p>We cater fully secured ticket booking service, under ATOL </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="EXFlight">
                <i className="fa fa-gbp"></i>
                <h5>Price Match Guarantee</h5>
                <p>Get guaranteed lowest price - To explore worldwide destination</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="EXFlight">
                <i className="fa fa-gift"></i>
                <h5>Exclusive Deals</h5>
                <p>We come up with exclusive airfare deals and seasonal offers</p>
              </div>
            </div>

          </div>
        </div>
      </section>
 
      <section className="flightPack flightoffer">
        <div className="container">
          <div className="row">
            <div className="col-md-12"><h2>Plan Your <strong>Destination Trip </strong></h2>
              <p>Fly2Go is really expert when it comes to travel – Our Travel Experts can get the best deals for you, that <br />definitely will come under your budget and travel dates.</p><br />
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-3 responsive-column">
              <div className="card-item flight-card">
                <div className="card-img"> <img src={bangkokImg } alt="akl" /></div>
                <div className="card-body">
                  <h3 className="card-title"><Link to="/Bangkok">Bangkok</Link></h3>
                  <div className="card-price d-flex align-items-center justify-content-between">
                    <p> <span className="price__from">From</span> <span className="price__num">£ 495.45</span> </p>
                    <Link to="/Bangkok" className="btn-text">Book Now</Link> </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 responsive-column">
              <div className="card-item flight-card">
                <div className="card-img"> <img src={Manilaimg} alt="dac" /> </div>
                <div className="card-body">
                  <h3 className="card-title"><Link to="/Manila">Manila</Link></h3>

                  <div className="card-price d-flex align-items-center justify-content-between">
                    <p> <span className="price__from">From</span> <span className="price__num">£ 490.45</span> </p>
                    <Link to="/Manila" className="btn-text">Book Now</Link> </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 responsive-column">
              <div className="card-item flight-card">
                <div className="card-img"> <img src={SingaporeImg} alt="isb" /> </div>
                <div className="card-body">
                  <h3 className="card-title"><Link to="/Singapore">Singapore</Link></h3>

                  <div className="card-price d-flex align-items-center justify-content-between">
                    <p> <span className="price__from">From</span> <span className="price__num">£ 439.40</span> </p>
                    <Link to="/Singapore" className="btn-text">Book Now</Link> </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 responsive-column">
              <div className="card-item flight-card">
                <div className="card-img"> <img src={HochminhImg} alt="jfk" /> </div>
                <div className="card-body">
                  <h3 className="card-title"><Link to="/HoChMinh">Ho Ch Minh</Link></h3>

                  <div className="card-price d-flex align-items-center justify-content-between">
                    <p> <span className="price__from">From</span> <span className="price__num">£ 489.45</span> </p>
                    <Link to="/HoChMinh" className="btn-text">Book Now</Link> </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 responsive-column">
              <div className="card-item flight-card">
                <div className="card-img"><img src={JakartaImg} alt="las" /> </div>
                <div className="card-body">
                  <h3 className="card-title"><Link to="/Jakarta">Jakarta</Link></h3>

                  <div className="card-price d-flex align-items-center justify-content-between">
                    <p> <span className="price__from">From</span> <span className="price__num">£ 479.45</span> </p>
                    <Link to="/Jakarta" className="btn-text">Book Now</Link> </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 responsive-column">
              <div className="card-item flight-card">
                <div className="card-img"><img src={islamabadImg} alt="los" /> </div>
                <div className="card-body">
                  <h3 className="card-title"><Link to="/Islamabad">Islamabad</Link></h3>

                  <div className="card-price d-flex align-items-center justify-content-between">
                    <p> <span className="price__from">From</span> <span className="price__num">£ 395.45</span> </p>
                    <Link to="/Islamabad" className="btn-text">Book Now</Link> </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 responsive-column">
              <div className="card-item flight-card">
                <div className="card-img"><img src={DhakaImg} alt="mbj" /> </div>
                <div className="card-body">
                  <h3 className="card-title"><Link to="/Dhaka">Dhaka</Link></h3>

                  <div className="card-price d-flex align-items-center justify-content-between">
                    <p> <span className="price__from">From</span> <span className="price__num">£ 389.45</span> </p>
                    <Link to="/Dhaka" className="btn-text">Book Now</Link> </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 responsive-column">
              <div className="card-item flight-card">
                <div className="card-img"><img src={melbourneImg} alt="mel" /> </div>
                <div className="card-body">
                  <h3 className="card-title"><Link to="/Melbourne">Melbourne</Link></h3>

                  <div className="card-price d-flex align-items-center justify-content-between">
                    <p> <span className="price__from">From</span> <span className="price__num">£ 588.45</span> </p>
                    <Link to="/Melbourne" className="btn-text">Book Now</Link> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="flightPack flightocall">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2>Have a enquiry? Choose an option to <strong>start your travel</strong></h2>
              <p>Fly2go is really expert when it comes to travel – Our Travel Experts can get the best deals for you, that definitely<br />will come under your budget and travel dates.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="enyq">
              <a href="tel:0208 095 0550">
                <i className="fa fa-phone"></i>
                <h5>0208 095 0550</h5>
                <p>Speak to our travel specialists </p>
                </a>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="enyq">
              <Link to="/CallBack">
                <i className="fa fa-envelope"></i>
                <h5>Send an email</h5>
                <p>Send an enquiry to travel expert </p>
                </Link>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="enyq">
              <Link to="/Contact">
                <i className="fa fa-headphones"></i>
                <h5>Customer Support</h5>
                <p>We'd love to hear from you!   </p>
                </Link>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="enyq">
              <Link to="/CallBack">
                <i className="fa fa-phone"></i>
                <h5>Request a call back</h5>
                <p>Our Travel Experts will call you </p>
                </Link>
              </div>
            </div>
          </div>
        

        </div>  
      </section>
      <div className={`mobilePopup ${show ? "show" : ""}`} align="center">
	<div className="container">
		<div id="mobclose" onClick={() => setShow(true)}><img src={closeIcon} alt="close" /> </div>
	<div className="row">
		<div className="col-md-12 airLogo"><h2>Book With Us</h2><a href="tel:0208 095 0550">24/7 Reservations &amp; Support</a></div>
		<div className="col-md-12 bookingbtn"><a href="tel:0208 095 0550">New Booking</a>
			<a href="tel:0208 095 0550">Changes</a> 
			<a href="tel:0208 095 0550">Cancellation</a> 
			<a href="tel:0208 095 0550">Customer Service</a></div>
		<div className="col-md-12 mb20"><img src={qrf} alt="qrf" /><br /><br /><img src={girlimg} alt="girlimg" /><br /></div>
		<div className="col-md-12"></div>
		<div className="col-md-12 airLogo"><a href="tel:0208 095 0550">No Hold - Call Answered in 5 Sec</a></div>
		<div className="col-md-12">
		<div className="callicons"> <a href="tel:0208 095 0550" className="circlePhone"></a>
  <h4> <a href="tel:0208 095 0550">0208 095 0550</a> </h4>
</div>
			<div className="col-md-12 airLogo"><br /><a href="tel:0208 095 0550">24/7 Helpline</a> </div>			
		</div>
		</div>
	</div>
</div>
     
    </>
  )
}

