import React from 'react';
import breadcrumbs from "../assets/images/breadcrumbs.png";
export default function Covid() {
  return (
    <>
      <section className="page_title" style={{ backgroundImage: `url(${breadcrumbs})` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2>COVID -19 </h2>
            </div>
          </div>
        </div>
      </section>
      <div className="container page">
        <div className="row">
          <div className="col-md-12">
            <h3>COVID -19 SPECIAL INFORMATION</h3>
            <p>Coming Soon...!</p>
          </div>
        </div>
      </div>
    </>
  )
}
