import React from 'react';
import { Table } from 'react-bootstrap';
import HomeHeroBanner from "../assets/banner/Chicago.jpg";
import FlightOffer from "../assets/images/FlightOffer.png";
import flightiCON from "../assets/Airline/flight.png";
import AA from "../assets/Airline/AA.png";
import DL from "../assets/Airline/DL.png";
import UA from "../assets/Airline/UA.png";
import LX from "../assets/Airline/LX.png";
import VS from "../assets/Airline/VS.png";
import AF from "../assets/Airline/AF.png";
import SearchEng from '../Company/SearchEng';
export default function Chicago() {
    return (
<>
            <div className="HeroBannerHome" style={{ backgroundImage: `url(${HomeHeroBanner})` }}>
                <div className="container dest">
                    <div className="row">
                        <div className="col-md-12 headertext">
                            <h1>Now Book Your Cheap Flight Tickets to <strong>Chicago.</strong></h1>
                            <p>Discounted Worldwide Tickets with Fly2Go.</p>
                        </div>
                        <div className="col-md-12">
                            <div className="mouse_scroll">

                                <div className="mouse">
                                    <div className="wheel"></div>
                                </div>
                                <div>
                                    <span className="m_scroll_arrows unu"></span>
                                    <span className="m_scroll_arrows doi"></span>
                                    <span className="m_scroll_arrows trei"></span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <SearchEng />
            <section className="flightPack flightoffer">
                <div className="container dest">
                    <div className="row">
                        <div className="col-md-9">
                            <Table bordered hover>
                                <tbody>
                                    <tr>
                                        <td><img src={AA} alt="AA" className="tairLogo" /></td>
                                        <td>LONDON(All)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>CHICAGO(ORD)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 515.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={DL} alt="DL" className="tairLogo" /></td>
                                        <td>BIRMINGHAM(BHX)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>CHICAGO(ORD)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 525.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={UA} alt="UA" className="tairLogo" /></td>
                                        <td>DUBLIN(DUB)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>CHICAGO(ORD)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 565.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={LX} alt="LX" className="tairLogo" /></td>
                                        <td>EDIBURGH(EDI)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>CHICAGO(ORD)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 575.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={AF} alt="AF" className="tairLogo" /></td>
                                        <td>GLASGOW(GLA)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>CHICAGO(ORD)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 595.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={VS} alt="VS" className="tairLogo" /></td>
                                        <td>MANCHESTER(MAN)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>CHICAGO(ORD)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 605.45</button></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-md-3"><img src={FlightOffer} alt="FlightOffer" className="FlightOffer" /> </div>
                    </div>
                    <div className="row page alignLeft">
                        <div className="col-md-12">
                            <h3>Book Cheap Flight Tickets with Fly2Go</h3>
                            </div>

                    </div>
                </div>
            </section>
        </>
    )
}
