import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HomeHeroBanner from "../assets/banner/accra.jpg";
import EthiopianAirways from './EthiopianAirways';
import SearchEng from '../Company/SearchEng';
import closeIcon from "../assets/images/close-mfp.png";
import AirlineOffer from "../assets/images/airlineOffer.jpg";
 
function Airlines() {
  const [show, setShow] = React.useState();
  const [contactNumber, setContactNumber] = useState('');
  const [isValid, setIsValid] = useState(true);

  const handleContactNumberChange = (e) => {
    const inputValue = e.target.value;
    // Use a regular expression to check if the input consists of exactly 10 digits
    const isValidNumber = /^\d{10}$/.test(inputValue);
    
    setContactNumber(inputValue);
    setIsValid(isValidNumber);
  };
  return (
    <>
      <div className="HeroBannerHome" style={{ backgroundImage: `url(${HomeHeroBanner})` }}>
        <div className="container dest">
          <div className="row">
            <div className="col-md-12 headertext">
              <h1>Now Book Your Cheap Flight Tickets</h1>
              <p>Discounted Worldwide Tickets with Fly2Go.</p>
            </div>
            <div className="col-md-12">
              <div className="mouse_scroll">

                <div className="mouse">
                  <div className="wheel"></div>
                </div>
                <div>
                  <span className="m_scroll_arrows unu"></span>
                  <span className="m_scroll_arrows doi"></span>
                  <span className="m_scroll_arrows trei"></span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <SearchEng />
      <div className="container flightExplore">
        <h2><strong>Select your Airline</strong></h2><br /><br />
        <div className="row">
          <div className="col-md-2">
            <Link to="/Airlines/Ethiopian-Airways">
              <div className='apLogo'>
                <img src={`https://fly2go.co.uk/FlightAirline/ET.png`} alt="Ethiopian Airlines" />
                <h4>Ethiopian Airlines</h4>
              </div>
            </Link>
          </div>
          <div className="col-md-2">
            <Link to="/Airlines/Qatar-Airways">
              <div className='apLogo'>
                <img src={`https://fly2go.co.uk/FlightAirline/QR.png`} alt="Qatar Airways" />
                <h4>Qatar Airways</h4>
              </div>
            </Link>
          </div>
          <div className="col-md-2">
            <Link to="/Airlines/Kenya-Airways">
              <div className='apLogo'>
                <img src={`https://fly2go.co.uk/FlightAirline/KQ.png`} alt="Kenya Airways" />
                <h4>Kenya Airways</h4>
              </div>
            </Link>
          </div>
          <div className="col-md-2">
            <Link to="/Airlines/Emirates-Airlines">
              <div className='apLogo'>
                <img src={`https://fly2go.co.uk/FlightAirline/EK.png`} alt="Emirates Airlines" />
                <h4>Emirates Airlines</h4>
              </div>
            </Link>
          </div>
          <div className="col-md-2">
            <Link to="/Airlines/British-Airways">
              <div className='apLogo'>
                <img src={`https://fly2go.co.uk/FlightAirline/BA.png`} alt="British Airways" />
                <h4>British Airways</h4>
              </div>
            </Link>
          </div>

        </div>

      </div>
      <div className='airlinepopup'>
      <div className={`mobilePopup ${show ? "show" : ""}`} align="center">
	<div className="container">
		{/* <div id="mobclose" onClick={() => setShow(true)}><img src={closeIcon} alt="close" /> </div> */}
	<div className="row">
		<div className="col-md-12 airLogo">
    <a href="tel:0208 095 0550"> <img src={AirlineOffer} alt="Airline Offer" /></a>
     </div>
		<div className="col-md-12 airpopfrom"> 
    <form action="https://www.fly2go.co.uk/OfferConnecting.php" method="post" autocomplete="off">
    <div className="row">
    <input type="hidden" name="xx_Sb" value="Airline Popup" />    
     <div className="col-md-3">
        <div className="form-group">
          <label>Your Name </label>
          <input type="text" name="fname" className="form-control" required placeholder='Enter Your Name' />
        </div>
      </div>
      <div className="col-md-3">
      <div className="input-group">
        <label>Contact Number</label>
        <span className="input-group-text">+44</span>
        <input
          type="number"
          name="cNumber"
          className={`form-control ${isValid ? '' : 'is-invalid'}`}
          maxLength={10}
          minLength={10}
          value={contactNumber}
          onChange={handleContactNumberChange}
          required placeholder='Enter Your UK Number'
        />
        {!isValid && (
          <div className="invalid-feedback">
            Please enter a valid 10-digit mobile number.
          </div>
        )}
      </div>
    </div>  
 
       
      <div className="col-md-2">
        <button type="submit" name="AirlineFlight" className="btn btn-primary flightBTN">Submit</button>
      </div>
    </div>
    
  </form>

    </div>
	 
	 <div className='airlinrno'>
		<div className="col-md-12 airLogo"><a href="tel:0208 095 0550">No Hold - Call Answered in 5 Sec</a></div>
		<div className="col-md-12">
		<div className="callicons"> <a href="tel:0208 095 0550" className="circlePhone"></a>
  <h4> <a href="tel:0208 095 0550">0208 095 0550</a> </h4>
</div>
			<div className="col-md-12 airLogo"><br /><a href="tel:0208 095 0550">24/7 Helpline</a> </div>			
		</div>
    </div>
		</div>
	</div>
</div>
</div>
    </>
  );
}

export default Airlines;
