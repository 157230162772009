import Carousel from 'react-bootstrap/Carousel';
import Banner1 from "../assets/banner/Banner1.jpg";
import Banner2 from "../assets/banner/Banner2.jpg";
import Banner3 from "../assets/banner/Banner3.jpg";
import Banner4 from "../assets/banner/Banner4.jpg";
import Banner5 from "../assets/banner/Banner5.jpg";
function UncontrolledExample() {
  return (
    <Carousel>
      <Carousel.Item>
      <img src={Banner1 } alt="" />
       </Carousel.Item>
      <Carousel.Item>
      <img src={Banner2 } alt="" />
     </Carousel.Item>
      <Carousel.Item>
      <img src={Banner4 } alt="" />
     </Carousel.Item>
     <Carousel.Item>
      <img src={Banner3 } alt="" />
     </Carousel.Item>
     <Carousel.Item>
      <img src={Banner5 } alt="" />
     </Carousel.Item>
    </Carousel>
  );
}

export default UncontrolledExample;