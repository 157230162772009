import React from 'react';
import { MetaTags } from 'react-meta-tags';
import { Table } from 'react-bootstrap';
import HomeHeroBanner from "../assets/banner/MNL.jpg";
import FlightOffer from "../assets/images/FlightOffer.png";
import flightiCON from "../assets/Airline/flight.png";
import EK from "../assets/Airline/EK.png";
import EI from "../assets/Airline/EI.png";
import QR from "../assets/Airline/QR.png";
import KL from "../assets/Airline/KL.png";
import MH from "../assets/Airline/MH.png";
import EY from "../assets/Airline/EY.png";
import SearchEng from '../Company/SearchEng';
export default function Manila() {
    return (
        <>
        <div className="HeroBannerHome" style={{ backgroundImage: `url(${HomeHeroBanner})` }}>
        <MetaTags>
  <title>Book your flight Ticket to Manila from London and get special offer on Fly2go. Call us on 0208-095-0550.</title>
  <meta name="description" content="Looking for a best deal in economy class or business class flights to Manila? With Fly2go, you can book Manila flights ticket from all major airlines at the cheapest price. From its street sea cuisine to its beaches, you can experience lots during a Manila holiday. Book your holidays now with FLY2GO or call us on 0208 095 0550." />
	<meta name="Keywords" content="Business class ticket to Manila, cheapest flight fare to manila, Low cost Airlines, flight ticket to Manila" />
  <meta name="robots" content="all" />
  <meta name=" author" content=" Fly2go " />
  <meta name="Language" content="English" />
  <meta name="distribution" content="Global" />
  <meta name="Robots" content="INDEX, FOLLOW" />
  <meta name="country" content="United Kingdom" />
  <link rel="canonical" href="https://www.fly2go.co.uk/Manila" />
</MetaTags>
            <div className="container dest">
                <div className="row">
                    <div className="col-md-12 headertext">
                        <h1>Now Book Your Cheap Flight Tickets to <strong>Manila.</strong></h1>
                        <p>Discounted Worldwide Tickets with Fly2Go.</p>
                    </div>
                    <div className="col-md-12">
                        <div className="mouse_scroll">

                            <div className="mouse">
                                <div className="wheel"></div>
                            </div>
                            <div>
                                <span className="m_scroll_arrows unu"></span>
                                <span className="m_scroll_arrows doi"></span>
                                <span className="m_scroll_arrows trei"></span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <SearchEng />
        <section className="flightPack flightoffer">
            <div className="container dest">
                <div className="row">
                    <div className="col-md-9">
                        <Table bordered hover>
                            <tbody>
                                <tr>
                                    <td><img src={QR} alt="QR" className="tairLogo" /></td>
                                    <td>LONDON(All)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>MANILA(MNL)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 
                                    495.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={EK} alt="EK" className="tairLogo" /></td>
                                    <td>BIRMINGHAM(BHX)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>MANILA(MNL)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 515.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={EI} alt="EI" className="tairLogo" /></td>
                                    <td>DUBLIN(DUB)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>MANILA(MNL)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 545.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={KL} alt="KL" className="tairLogo" /></td>
                                    <td>EDIBURGH(EDI)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>MANILA(MNL)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 565.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={MH} alt="MH" className="tairLogo" /></td>
                                    <td>GLASGOW(GLA)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>MANILA(MNL)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 595.45</button></td>
                                </tr>
                                <tr>
                                    <td><img src={EY} alt="EY" className="tairLogo" /></td>
                                    <td>MANCHESTER(MAN)</td>
                                    <td><img src={flightiCON} alt="flightiCON" /></td>
                                    <td>MANILA(MNL)</td>
                                    <td><button type="button" className="btn btn-primary">&pound; 615.45</button></td>
                                </tr>
                                


                            </tbody>
                        </Table>
                    </div>
                    <div className="col-md-3"><img src={FlightOffer} alt="FlightOffer" className="FlightOffer" /> </div>
                </div>
                <div className="row page alignLeft">
                    <div className="col-md-12">
                        <h3>Book Cheap Flight Tickets with Fly2Go.</h3>
                          </div>

                </div>
            </div>
        </section>
    </>
    )
}
