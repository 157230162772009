import React from 'react';
import { Link } from 'react-router-dom';
import AllCard from "../assets/images/Footer_img.png";
import AllCard2 from "../assets/images/Footer_img1.png";
import PaymentOpction from "../assets/images/PaymentOpctionCard.png";
import PopularHoliday from './PopularHoliday';
//import Popup from "../Include/Popup";
export default function Footer() {
  
    return (
        <>
        <div className="container">
        <div className="row">
    <div className="col-md-9 col-sm-9 col-xs-9 padding15"> <img src={PaymentOpction} alt="PaymentOpction" /> </div>
    <div className="col-md-3 col-sm-3 col-xs-3 padding15 paymentBtn">
      <Link to="/Self-Payment">Self Payment</Link></div>
    </div>
    </div>
        <footer className="site-footer">
  <div className="container">
    <div className="row">
    
      <div className="col-sm-12 col-md-6">
        <h6>About Fly2Go</h6>
        <p className="text-justify">Travel presents an opportunity to discover the unseen places, to meet complete strangers, to explore distinct cultures and traditions and a road that leads you to self-discovery, and we at Fly2go.co.uk <Link to="/About">more...</Link></p>
        
      </div>
      <div className="col-xs-6 col-md-3">
        <h6>Fly2Go</h6>
        <ul className="footer-links">
          <li>
            <Link to="/FAQ">
            FAQ
            </Link>
          </li>
          <li>
            <Link to="/COVID">
            COVID-19
            </Link>
          </li>
          <li>
            <Link to="/TermsConditions">
            Terms and Conditions
            </Link>
          </li>
          <li>
            <Link to="/PriceMatchPromise">
            Price Match Promise
            </Link>
          </li>
          <li>
            <Link to="/PrivacyPolicy">
            Privacy Policy
            </Link>
          </li>
          <li>
            <Link to="/About">
            About Us
            </Link>
          </li>
          <li>
            <Link to="/Contact">
            Contact Us
            </Link>
          </li>
        </ul>
      </div>
      <div className="col-xs-6 col-md-3">
        <h6>Flight Offers</h6>
        <ul className="footer-links">
          <li>
            <Link to="/Accra">
            Cheap Flight To Accra
            </Link>
          </li>
          <li>
            <Link to="/AddisAbaba">
            Cheap Flight To Addis Ababa
            </Link>
          </li>
          <li>
            <Link to="/Auckland">
            Cheap Flight To Auckland
            </Link>
          </li>
          <li>
            <Link to="/Bangkok">
            Cheap Flight To Bangkok
            </Link>
          </li>
          <li>
            <Link to="/Barbados">
            Cheap Flight To Barbados
            </Link>
          </li>
          <li>
            <Link to="/Cairo">
            Cheap Flight To Cairo
            </Link>
          </li>
          <li>
            <Link to="/Chicago">
            Cheap Flight To Chicago
            </Link>
          </li>
        </ul>
      </div>
      
    </div>
   
  </div>
  <div className="container">
    <div className="row">
    
    <div className="col-md-12 col-sm-12 col-xs-12">   
      <p className="discla"><strong>DISCLAIMER:</strong> Fly2Go.co.uk is an independent online travel service provider that provides cost-effective travel services. We do not claim that we are Airlines. Brands displayed on our pages are owned by the respective brand owners. In case if you find any content on our website which violates your privacy or any copyright laws then report us on info@Fly2Go.co.uk call us 0208 095 0550.</p>
      </div>
      <div className="col-md-12 col-sm-12 col-xs-12">
        <p className="copyright-text">Copyright &copy; 2023 All Rights Reserved. </p>
      </div>
    </div>
  </div>
</footer>

{/* <Popup /> */}
<div className="callnoFt"><i className="fa fa-phone"></i> <a href="tel: 0208 095 0550"> 0208 095 0550</a></div>


        </>
    )
}

