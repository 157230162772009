import React from 'react';
import breadcrumbs from "../assets/images/breadcrumbs.png";
export default function PrivacyPolicy() {
    return (
        <>
            <section className="page_title" style={{ backgroundImage: `url(${breadcrumbs})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>Privacy Policy</h2>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container page">
                <div className="row">
                    <div className="col-md-12">
                    <h3>Privacy Policy</h3>
<h4>Contract</h4>
<p>The site you are visiting is owned and administered by Fly2Go. Please note there may be links on this site to third parties at which point you must make yourself aware of their own privacy and security policies. </p>
<h4>Email Alerts </h4>
<p>Fly2Go collects information for a number of reasons. We have an opt-in email alert service where we will hold your email address. This email address is only used for marketing what we believe will be useful information about air fare services - we will not rent or sell your email address to anyone else. If you are unhappy about the emails we are sending you please email - info@fly2go.co.uk from the email address that is receiving the mails. If you are unable to do this then please email info@fly2go.co.uk the email address you wish to remove. </p>
<h4>Online Booking </h4>
<p>If you are making a booking with Fly2Go or one of its affiliated sites we will capture personal information about the lead passenger (name, address, telephone number and email address). In addition we will collect the name and date of birth of all of the travelling passengers. This information will be stored on our own servers to help us handle your booking, in addition your name and travelling details will be passed onto the airline or supplier we are buying the service from. It should be noted that this information may then be passed onto certain government organizations as required by law. </p>
<h4>Payment Details </h4>
<p>We collect payment details including credit card details from you as part of the booking transaction. This information is used to gain payment for services and is then removed from our servers. in some situations we will contact you to confirm these details by telephone. We will never ask for your payment details over email as this not a secure method of transport and we would ask you never to send such details in this way. </p>
<h4>Collection Of Personal Information </h4>
<p>When you send personal information to us over the internet, your data is protected by secure socket layer (ssl) technology to ensure safe transmission. This information will be store on secure fileservers which are protected from the internet by industry standard firewalls. Your information will be backed up on to secondary servers and eventually tape backup. At any time, you can contact us to find out the details we hold on you - please contact. </p>
<h4>Cookies</h4>
<p>Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. By continuing to browse the site, you are agreeing to our use of cookies. A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive.<br />
  A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive. </p>
<h4>Security</h4>
<p>To protect personal information transmitted to us, we use a security system that includes firewall technologies, intrusion detection systems, logical network segmentation, restricted access to stored profile information, and security restrictions to our databases and systems. We permit authorized personnel only to access personal information. We use industry-standard, secure-socket-layer (SSL) technology, which is utilized by most popular browsers, including Netscape and Microsoft Explorer, to encrypt (or convert into code) your personal information before it is transferred across the Internet. To further safeguard your most sensitive credit card information, we transfer such information across the Internet in an encrypted format and then store such information in our secure database. </p>


                    </div>
                </div>
            </div>
        </>
    )
}
