import React, { Component } from 'react';
import { MoviesData, renderMovieTitle } from './ToAirportList';
import Autocomplete from 'react-autocomplete';
class ToAirport extends Component {
  state = { val: '' };
  render() {
    return (
      <div className="autocomplete-wrapper">
        <Autocomplete 
          value={this.state.val}
          inputProps={{ name: 'Destination' }}
          items={MoviesData()}
          getItemValue={item => item.name}
          shouldItemRender={renderMovieTitle}
          renderMenu={item => (
            <div className="dropdown form-control">
              {item}
            </div>
          )}
          renderItem={(item, isHighlighted) =>
            <div className={`item ${isHighlighted ? 'selected-item' : ''}`}>
              {item.name}
            </div>
          }
     
         onChange={(event, val) => this.setState({ val })}
          onSelect={val => this.setState({ val })}
        />
      </div>
    );
  }
}
export default ToAirport;