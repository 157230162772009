import React from 'react';
import { MetaTags } from 'react-meta-tags';
import { Table } from 'react-bootstrap';
import HomeHeroBanner from "../assets/banner/Lagos.jpg";
import FlightOffer from "../assets/images/FlightOffer.png";
import flightiCON from "../assets/Airline/flight.png";
import EK from "../assets/Airline/EK.png";
import BA from "../assets/Airline/BA.png";
import KL from "../assets/Airline/KL.png";
import TK from "../assets/Airline/TK.png";
import VS from "../assets/Airline/VS.png";
import AF from "../assets/Airline/AF.png";
import SearchEng from '../Company/SearchEng';
export default function Lagos() {
    return (
        <>
            <div className="HeroBannerHome" style={{ backgroundImage: `url(${HomeHeroBanner})` }}>
            <MetaTags>
  <title>Best tailor made deal, holiday packages and cheap flight ticket to London to Lagos with Fly2go. Call us on 0208-095-0550</title>
  <meta name="description" content="Enjoy your upcoming holiday vacations in cheap and affordable airfare including hotel booking, car rental, and airport transfer to Lagos from UK. Call us on 0208 095 0550. Find cheap flight ticket to Lagos only on Fly2go." />
	<meta name="Keywords" content="Tailor made deal, cheap flight to Lagos, direct flight to Lagos, best flight deal to Lagos, low cost flight deal to Lagos" />
  <meta name="robots" content="all" />
  <meta name=" author" content=" Fly2go " />
  <meta name="Language" content="English" />
  <meta name="distribution" content="Global" />
  <meta name="Robots" content="INDEX, FOLLOW" />
  <meta name="country" content="United Kingdom" />
  <link rel="canonical" href="https://www.fly2go.co.uk/Lagos" />
</MetaTags>
                <div className="container dest">
                    <div className="row">
                        <div className="col-md-12 headertext">
                            <h1>Now Book Your Cheap Flight Tickets to <strong>Lagos.</strong></h1>
                            <p>Discounted Worldwide Tickets with Fly2Go.</p>
                        </div>
                        <div className="col-md-12">
                            <div className="mouse_scroll">

                                <div className="mouse">
                                    <div className="wheel"></div>
                                </div>
                                <div>
                                    <span className="m_scroll_arrows unu"></span>
                                    <span className="m_scroll_arrows doi"></span>
                                    <span className="m_scroll_arrows trei"></span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <SearchEng />
            <section className="flightPack flightoffer">
                <div className="container dest">
                    <div className="row">
                        <div className="col-md-9">
                            <Table bordered hover>
                                <tbody>
                                    <tr>
                                        <td><img src={VS} alt="VS" className="tairLogo" /></td>
                                        <td>LONDON(All)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>LAGOS(LOS)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 495.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={EK} alt="EK" className="tairLogo" /></td>
                                        <td>BIRMINGHAM(BHX)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>LAGOS(LOS)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 515.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={KL} alt="KL" className="tairLogo" /></td>
                                        <td>DUBLIN(DUB)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>LAGOS(LOS)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 545.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={AF} alt="AF" className="tairLogo" /></td>
                                        <td>EDIBURGH(EDI)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>LAGOS(LOS)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 565.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={TK} alt="TK" className="tairLogo" /></td>
                                        <td>GLASGOW(GLA)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>LAGOS(LOS)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 595.45</button></td>
                                    </tr>
                                    <tr>
                                        <td><img src={BA} alt="BA" className="tairLogo" /></td>
                                        <td>MANCHESTER(MAN)</td>
                                        <td><img src={flightiCON} alt="flightiCON" /></td>
                                        <td>LAGOS(LOS)</td>
                                        <td><button type="button" className="btn btn-primary">&pound; 615.45</button></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-md-3"><img src={FlightOffer} alt="FlightOffer" className="FlightOffer" /> </div>
                    </div>
                    <div className="row page alignLeft">
                        <div className="col-md-12">
                            <h3>Book Cheap Flight Tickets with Fly2Go.</h3>
                            </div>

                    </div>
                </div>
            </section>
        </>
    )
}
