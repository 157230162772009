import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import logo from "../assets/images/fly2go.png";
import pst from "../assets/images/pts-logo.jpg";
import callus from "../assets/images/callUs.png";
export default function Header() {

  return (
    <>  <header>
      <div className="container-fluid">

        <Navbar collapseOnSelect expand="lg" className="navbg">
          <div className="myheader container-fluid">

            <Navbar.Brand><Link to="/"><img src={logo} alt="Logo" /></Link></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link><Link to="/">Home</Link></Nav.Link>
                <div className="dropdown">
                    <Nav.Link><Link to="/" className="dropbtn">Destinations <i className="fa fa-caret-down"></i></Link></Nav.Link>
                    <div className="dropdown-content">
                      <Nav.Link><Link to="/Dallas">Cheap Flight To Dallas </Link></Nav.Link>
                      <Nav.Link><Link to="/Dhaka">Cheap Flight To Dhaka</Link></Nav.Link>
                      <Nav.Link><Link to="/HoChMinh">Cheap Flight To Ho Ch Minh</Link></Nav.Link>
                      <Nav.Link><Link to="/Islamabad">Cheap Flight To Islamabad</Link></Nav.Link>
                      <Nav.Link><Link to="/Jakarta">Cheap Flight To Jakarta</Link></Nav.Link>
                      <Nav.Link><Link to="/Johannesburg">Cheap Flight To Johannesburg</Link></Nav.Link>
                      <Nav.Link><Link to="/Kingston">Cheap Flight To Kingston</Link></Nav.Link>
                      <Nav.Link><Link to="/Lagos">Cheap Flight To Lagos</Link></Nav.Link>
                      <Nav.Link><Link to="/LosAngeles">Cheap Flight To LosAngeles</Link></Nav.Link>
                      <Nav.Link><Link to="/Manila">Cheap Flight To Manila</Link></Nav.Link>
                      <Nav.Link><Link to="/Melbourne">Cheap Flight To Melbourne</Link></Nav.Link>
                      <Nav.Link><Link to="/NewYork">Cheap Flight To New York</Link></Nav.Link>
                      <Nav.Link><Link to="/Singapore">Cheap Flight To Singapore</Link></Nav.Link>
                      <Nav.Link><Link to="/Sydney">Cheap Flight To Sydney</Link></Nav.Link>
                      
                    </div>
                  </div>
                  <Nav.Link><Link to="/Airlines">Airlines</Link></Nav.Link>
                <Nav.Link><Link to="/About">About Us</Link></Nav.Link>
                <Nav.Link><Link to="/Contact">Contact Us</Link></Nav.Link>
              
                <div className="callno"><i className="fa fa-phone"></i> <a href="tel: 0208 095 0550"> 0208 095 0550</a> <span>Call Us for more offer!</span></div>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </div>

    </header>
    </>

  )
}