import React from 'react';
import breadcrumbs from "../assets/images/breadcrumbs.png";
export default function PriceMatchPromise() {
    return (
        <>
        <section className="page_title" style={{ backgroundImage: `url(${breadcrumbs})` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2>Price Match Promise</h2>             
            </div>
          </div>
        </div>
      </section>
      <div className="container page">
      <div className="row">
        <div className="col-md-12">
       <h3>Price Match Promise</h3>
        
       </div>
      </div>
    </div>
      </>
    )
}
