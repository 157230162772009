import React from 'react';
import { MetaTags } from 'react-meta-tags';
import breadcrumbs from "../assets/images/breadcrumbs.png";

function Selfpayment() {
  return (
    <>
     <section className="page_title" style={{ backgroundImage: `url(${breadcrumbs})` }}>
        <MetaTags>
        <title>Self Payment</title>
        </MetaTags>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2>Self Payment</h2>             
            </div>
          </div>
        </div>
      </section>
      <div className="container page">
      <div className="row">
    <div className="col">
      <div className="card">
        <div className="card-header bg-primary text-white cbgform"> ENTER PAYMENT DETAILS BELOW </div>
        <div className="card-body spayment">
        <form action="https://www.fly2go.co.uk/Payment/Pay.php" method="post" autocomplete="off">
        <div class="col-lg-3">
            <div className="form-group">
              <label>First Name :</label>
              <input name="pay_first_name" type="text" id="pay_first_name" class="form-control" required="" />
             </div>
             </div>
             <div class="col-lg-3">
            <div className="form-group">
              <label>Last Name :</label>
              <input name="pay_last_name" type="text" id="pay_last_name" class="form-control" required="" />
               </div>
               </div>
               <div class="col-lg-3">
              <div className="form-group">
              <label>Amount(in GBP):</label>
              <input name="pay_amount" type="text" id="pay_amount" class="form-control" onkeypress="return onlyNumbers();" required="" />
              </div>
              </div>
              <div class="col-lg-3 paymentBtn">
              <div className="form-group pbtn"><br />
               <input type="submit" name="submit" class="theme-btn send-message-btn" value="Pay Online" />
              </div>
              </div>
             
          </form>
        </div>
      </div>
    </div>
     
  </div>
    </div>
    </>
  )
}

export default Selfpayment
